import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './style.css'; // Your global styles
import './assets/fonts.css'; // Custom fonts
import Alpine from 'alpinejs'; // Alpine.js import

window.Alpine = Alpine; // Expose Alpine globally
Alpine.start(); // Initialize Alpine.js

import 'remixicon/fonts/remixicon.css'; // Remixicon for icons

// Create and mount Vue app
const app = createApp(App);
app.use(router); // Register Vue Router
app.mount('#app'); // Mount to #app element


