<template>
<div class="w-64 bg-[#17252A] min-h-screen transition-all flex flex-col justify-between" dir="rtl">
  <!-- Logo -->
  <a href="/user"><img src="../../assets/logosvg.svg" alt="" class="h-[50px] my-1 mx-auto" /></a>
  <!-- <div>
        dfdsfd
      </div> -->
  <!-- Expert header content -->
  <br><br><br><br>
  <!-- <div class="text-white mx-auto text-[1.25rem]"> خاصية الخبير </div> -->
  <ul class="flex flex-col justify-between mb-auto">
    <div>
      <li class="mb-2">
        <router-link to="/user/user-project-info" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-project-info') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
          <span class="font-Cairo">
            تفاصيل مشروعك
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-reserve-build-bmc" class="flex flex-row  px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-reserve-build-bmc') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
          </svg>
          <span class="font-Cairo">
            بناء نموذج العمل التجاري BMC
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-reserve-financial-study" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-reserve-financial-study') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v7.5m2.25-6.466a9.016 9.016 0 0 0-3.461-.203c-.536.072-.974.478-1.021 1.017a4.559 4.559 0 0 0-.018.402c0 .464.336.844.775.994l2.95 1.012c.44.15.775.53.775.994 0 .136-.006.27-.018.402-.047.539-.485.945-1.021 1.017a9.077 9.077 0 0 1-3.461-.203M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>

          <span class="font-Cairo">
            اعداد الدراسة المالية
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-label" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-label') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
          </svg>
          <span class="font-Cairo">
            الحصول على وسم لابل
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-londa" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-londa') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
          </svg>

          <span class="font-Cairo">
            تسجيل براءة اختراع، حماية الملكية الفكرية
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-finance" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-finance') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

          <span class="font-Cairo">
            طلب التمويل
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-free-expert" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-free-expert') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <span class="font-Cairo">
            حجز موعد مع خبير
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/user/user-visual-identity" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/user/user-visual-identity') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z" />
          </svg>
          <span class="font-Cairo">
            بناء الهوية البصرية
          </span>
        </router-link>
      </li>
    </div>
  </ul>

  <span class="text-white mb-4 text-center font-Cairo">copyright 2024 <span>@</span></span>

</div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const isActive = (path) => {
  return route.path === path;
};
</script>

<style scoped>
@media (max-width: 768px) {
  .w-64 {
    display: none;
    /* Hide sidebar in mobile view */
  }
}
</style>