<template>
<div class="bg-white font-[sans-serif] my-4">
    <div class="max-w-6xl mx-auto">
        <div class="text-center">
            <h2
                class="text-3xl font-extrabold font-Cairo text-gray-800 inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-[#3AAFA9] after:rounded-full">
                المشاريع</h2>
        </div>
        <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-16 max-lg:max-w-3xl max-md:max-w-md mx-auto font-Cairo">
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative w-full object-cover size-40" dir="auto">
                    <svg class="rotate-[135deg] size-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-[#3aafa962]"
                            stroke-width="1" stroke-dasharray="75 100"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-[#2B7A78]"
                            stroke-width="2" stroke-dasharray="33.75 100"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                        <span class="text-4xl font-bold text-[#2B7A78]">45</span>
                        <span class="text-[#2B7A78] block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">نسبة المشاريع المعالجة</h3>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative w-full object-cover size-40" dir="auto">
                    <svg class="rotate-[135deg] size-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-[#3aafa962]"
                            stroke-width="1" stroke-dasharray="75 100"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-[#2B7A78]"
                            stroke-width="2" stroke-dasharray="22.5 100"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                        <span class="text-4xl font-bold text-[#2B7A78]">30</span>
                        <span class="text-[#2B7A78] block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">نسبة المشاريع قيد المعالجة</h3>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative w-full object-cover size-40" dir="auto">
                    <svg class="rotate-[135deg] size-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-[#3aafa962]"
                            stroke-width="1" stroke-dasharray="75 100"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-[#2B7A78]"
                            stroke-width="2" stroke-dasharray="18.75 100"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                        <span class="text-4xl font-bold text-[#2B7A78]">25</span>
                        <span class="text-[#2B7A78] block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">نسبة المشاريع قيد الإنتضار</h3>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>