<template><!-- hero section -->
<section id="hero-section" class="" dir="rtl">
	<div class="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between"
		dir="ltr">
		<div class="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
			<img src="../../assets/heroBG.svg" alt="" class="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
		</div>
		<div class="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-lg lg:text-left" >
			<h1 class="text-5xl font-Cairo leading-none sm:text-6xl">خذ
				<span class="text-[#3AAFA9] font-Cairo">خطوة</span> نحو النجاح
			</h1>

			<!-- Collapsible Content -->
			<div class="overflow-hidden transition-all" :class="showMore ? 'max-h-full' : 'max-h-20'" dir="rtl"> 
				<p class="mt-6 mb-8 text-lg text-center font-Cairo sm:mb-12">ضع افكارك و خطوتك الاولى معنا
					<br class="hidden md:inline lg:hidden">فمع خطوة يمكن للطموحات ان تتجسد
				<br>حاضنة الأعمال الرقمية "خطوة" هي منصة افتراضية متخصصة في دعم إنشاء وتطوير المشاريع الناشئة، موجهة نحو توفير جميع أنواع الدعم الإداري، التقني، والاقتصادي للمبادرين ورواد الأعمال. تعمل الحاضنة على مرافقة المشاريع منذ الفكرة الأولى وحتى تحقيق النجاح والتخرج، مع ضمان حصولها على التمويل اللازم للنمو المستدام. تقدم "خطوة" خدماتها بشكل رقمي بالكامل، مما يسمح بالوصول إلى الموارد والخبرات المتنوعة بسهولة ومرونة. يدير الحاضنة فريق أكاديمي ومهني يمتلك خبرة واسعة في ريادة الأعمال، مما يضمن تقديم استشارات متميزة وحلول فعّالة تسهم في تعزيز فرص النجاح والتوسع في السوق.
				</p>
			</div>
			
			<!-- Buttons -->
			<div
				class="flex flex-col space-y-4 items-center sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
				<a href="#" @click="toggleShowMore" class="text-[#3aafa9] font-Cairo inline-flex items-center mt-5">
              <span> {{ !showMore ? 'إقرأ المزيد' : 'إقرأ أقل' }}</span>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-5" :class="{
                  'rotate-90': showMore,
                  '-rotate-90 ': !showMore
                }">
                }">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>
            </a>
			<RouterLink class="px-8 w-full sm:w-fit py-3 text-lg font-Cairo border rounded border-gray-800"
					to="/login">تسجيل الدخول</RouterLink>
				<RouterLink class="px-8  w-full sm:w-fit py-3 text-lg font-Cairo rounded bg-[#3AAFA9] text-gray-50"
					to="/register">سجل الآن</RouterLink>

				
					
			</div>
		</div>
	</div>
</section>
<!-- end hero section -->
</template>

<script setup>
import { ref } from 'vue'

const showMore = ref(false);

const toggleShowMore = () => {
	showMore.value = !showMore.value;
}
</script>

<style scoped>
.transition-all {
	transition: max-height 0.5s ease-in-out;
}
</style>