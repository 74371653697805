<template><!-- OurI nterests section -->
<section id="OurInterests-section" class="relative isolate pt-14 lg:px-8" dir="rtl">
  <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
    <div
      class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
      style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
  </div>
  <div class="container max-w-5xl  py-12 mx-auto">
    <div class="grid gap-4 mx-4 sm:grid-cols-12">
      <div class="col-span-12 sm:col-span-3">
        <div
          class="text-center sm:text-right mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-[#3AAFA9]">
          <h3 class="text-3xl font-Cairo">اهتماماتنا</h3>
          <span class="text-sm font-Cairo tracking-wider  text-gray-600">حاضنة "خطوة" تستهدف استقطاب ودعم مجموعة
            متنوعة من المشاريع والأفكار المبتكرة التي تساهم في تطوير حلول فعالة ومستدامة في مختلف القطاعات الاقتصادية.
            من بين أهم المشاريع والأفكار التي يجب أن تتوفر في الحاضنة:</span>
        </div>
      </div>
      <div class="relative col-span-12 space-y-6 sm:col-span-9">
        <div
          class="col-span-12 space-y-12 relative sm:px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:-bottom-0 sm:before:w-0.5 sm:before:-right-3 before:bg-gray-300">
          <div
            class="flex flex-col w-full sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <h3 class="text-xl font-Cairo tracking-wide">التكنولوجيا المالية</h3>
            <p class="mt-3 font-Cairo">مشاريع تستهدف تطوير حلول دفع رقمية، تطبيقات إدارة الأموال، أو منصات لتسهيل
              التمويل والاستثمار الرقمي. يعتبر هذا القطاع محوريًا في تسهيل الأعمال وتعزيز الاقتصاد الرقمي.</p>
          </div>
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <h3 class="text-xl font-Cairo tracking-wide">التعليم الإلكتروني</h3>
            <p class="mt-3 font-Cairo">أفكار مبتكرة لتحسين التعليم من خلال التكنولوجيا، مثل منصات التعليم عبر الإنترنت،
              أدوات التعلم الذكية، والتطبيقات التي تعتمد على الذكاء الاصطناعي لتقديم تجارب تعليمية مخصصة.</p>
          </div>

          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <div class="absolute inset-x-0 -top-100 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true">
              <div
                class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
            </div>
            <div
              class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true">
              <div
                class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
            </div>
            <h3 class="text-xl font-Cairo tracking-wide">التكنولوجيا الصحية</h3>
            <p class="mt-3 font-Cairo">مشاريع تركز على الابتكار في القطاع الصحي، مثل تطبيقات الرعاية الصحية عن بعد،
              الأجهزة الذكية لمراقبة صحة المرضى، والحلول القائمة على الذكاء الاصطناعي لتشخيص الأمراض وتقديم الرعاية
              الشخصية.</p>
          </div>
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <h3 class="text-xl font-Cairo tracking-wide">الطاقة المتجددة والاستدامة</h3>
            <p class="mt-3 font-Cairo">أفكار تهدف إلى تعزيز الاعتماد على الطاقة المتجددة وتطوير تقنيات تساعد في تحقيق
              الاستدامة البيئية، مثل حلول الطاقة الشمسية، إدارة النفايات، أو التقنيات النظيفة التي تخفف من التلوث
              البيئي.</p>
          </div>
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <h3 class="text-xl font-Cairo tracking-wide">التجارة الإلكترونية</h3>
            <p class="mt-3 font-Cairo">مشاريع تركز على تحسين تجربة التجارة الرقمية، سواء من خلال تطوير منصات للتجارة
              الإلكترونية، أو حلول لوجستية مبتكرة لتسليم المنتجات، أو أدوات لتحليل بيانات العملاء وتحسين المبيعات.</p>
          </div>
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <div class="absolute inset-x-0 -top-100 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true">
              <div
                class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
            </div>
            <div
              class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true">
              <div
                class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
            </div>
            <h3 class="text-xl font-Cairo tracking-wide">التكنولوجيا الزراعية</h3>
            <p class="mt-3 font-Cairo">أفكار تهدف إلى تحسين الإنتاج الزراعي باستخدام التكنولوجيا، مثل الزراعة الذكية،
              حلول الري الرقمية، أو الابتكارات التي تساعد في تحسين كفاءة استخدام الموارد الزراعية.</p>
          </div>
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <h3 class="text-xl font-Cairo tracking-wide">التسويق الرقمي</h3>
            <p class="mt-3 font-Cairo">مشاريع تساعد الشركات الناشئة على تطوير استراتيجيات تسويق رقمية فعالة، مثل أدوات
              تحسين محركات البحث (SEO)، تحليل البيانات الضخمة لتحديد سلوك العملاء، أو تطوير استراتيجيات الإعلانات عبر
              وسائل التواصل الاجتماعي.</p>
          </div>
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:right-[-35px] sm:before:z-[1] before:bg-[#3AAFA9]  rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
            <div class="absolute inset-x-0 -top-100 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true">
              <div
                class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
            </div>
            <div
              class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true">
              <div
                class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
            </div>
            <h3 class="text-xl font-Cairo tracking-wide">المدن الذكية وإنترنت الأشياء</h3>
            <p class="mt-3 font-Cairo">مشاريع مبتكرة تهدف إلى تطوير حلول للمدن الذكية، مثل أنظمة المراقبة والتحكم في
              استهلاك الطاقة، حلول النقل الذكي، أو الأجهزة المتصلة التي تسهل الحياة اليومية من خلال إنترنت الأشياء.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div
    class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    aria-hidden="true">
    <div
      class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
      style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
  </div>
</section>
<!-- end Our Interests section -->
</template>
<script setup>

</script>

<style scoped></style>