<template>
<div class="w-64 bg-[#17252A] min-h-screen transition-all flex flex-col justify-between" dir="rtl">
  <!-- Logo -->
  <a href="/admin">
    <img src="../../assets/logosvg.svg" alt="" class="h-[50px] my-1 mx-auto" />
  </a>

  <!-- Expert header content -->
  <br><br><br><br>
  <!-- <div class="text-white mx-auto text-[1.25rem]"> خاصية الخبير </div> -->
  <ul class="flex flex-col justify-between mb-auto">
    <div>
      <li class="mb-2">
        <router-link to="/admin" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/admin') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
          </svg>

          <span class="font-Cairo">
            إحصائيات
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/admin/expert-managment" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/admin/expert-managment') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>

          <span class="font-Cairo">
            إدارة الخبراء
          </span>
        </router-link>
      </li>
      <li class="mb-2">
        <router-link to="/admin/user-managment" class="flex flex-row px-4 py-2 text-white"
          :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/admin/user-managment') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
          </svg>
          <span class="font-Cairo">
            إدارة المستخدمين
          </span>
        </router-link>
      </li>

    </div>
  </ul>
  <span class="text-white mb-4 text-center font-Cairo ">copyright 2024 <span>@</span></span>

</div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const isActive = (path) => {
  return route.path === path;
};
</script>

<style scoped>
@media (max-width: 768px) {
  .w-64 {
    display: none;
    /* Hide sidebar in mobile view */
  }
}
</style>