<template>
<!-- Expert body content -->
  
  <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 font-Cairo">المشاريع المعلقة</h1>
            <!-- <p class="lg:w-2/3 mx-auto leading-relaxed text-base font-Cairo">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them.</p> -->
          </div>
          <div class="flex flex-wrap -m-4">
              <div class="p-4 lg:w-1/3">
                  <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 font-Cairo">CATEGORY</h2>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 font-Cairo">
                        Solaris Domus الطاقة الشمسية المنزلية
                      </h1>
                      <p class="leading-relaxed mb-3 font-Cairo">
                        مشروع "الطاقة الشمسية المنزلية": Solaris Domus تطوير أنظمة طاقة شمسية صغيرة لتزويد المنازل بالطاقة المتجددة، مما يساعد في تقليل الاعتماد على مصادر الطاقة التقليدية.
                      </p>
                      <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">
                        إقرأ المزيد
                          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                          </svg> -->
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                          </svg>
                      </a>
                  </div>
              </div>
              <div class="p-4 lg:w-1/3">
                  <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative border-black">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 font-Cairo">CATEGORY</h2>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 font-Cairo">
                        AgriSustain منصة "الزراعة المستدامة"
                      </h1>
                      <p class="leading-relaxed mb-3 font-Cairo">
                        منصة "الزراعة المستدامة": AgriSustain تطبيق يربط المزارعين بالموارد والخبرات الزراعية المستدامة، بما في ذلك تقنيات الري الذكي وزراعة المحاصيل المناسبة للبيئة المحلية.
                      </p>
                      <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">إقرأ المزيد
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                      </a>
                  </div>
              </div>
              <div class="p-4 lg:w-1/3">
                  <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 font-Cairo">CATEGORY</h2>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 font-Cairo">
                        RecycloUrbanus مشروع "إعادة تدوير النفايات"
                      </h1>
                      <p class="leading-relaxed mb-3 font-Cairo">
                        مشروع "إعادة تدوير النفايات": RecycloUrbanus إنشاء مركز لإعادة تدوير النفايات الصلبة، يهدف إلى تحسين إدارة النفايات وتقليل التلوث في المدن الجزائرية.
                      </p>
                      <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">إقرأ المزيد
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                      </a>
                  </div>
              </div>
              <div class="p-4 lg:w-1/3">
                  <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 font-Cairo">CATEGORY</h2>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 font-Cairo">
                        EcoTouris تطبيق "السياحة البيئية"
                      </h1>
                      <p class="leading-relaxed mb-3 font-Cairo">
                        تطبيق "السياحة البيئية": EcoTouris منصة رقمية تروّج للسياحة البيئية في الجزائر، وتساعد الزوار في اكتشاف المحميات الطبيعية والمناطق السياحية غير التقليدية.
                      </p>
                      <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">إقرأ المزيد
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                      </a>
                  </div>
              </div>
              <div class="p-4 lg:w-1/3">
                  <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 font-Cairo">CATEGORY</h2>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 font-Cairo">
                        NutriLocalis مشروع "التغذية الصحية"
                      </h1>
                      <p class="leading-relaxed mb-3 font-Cairo">
                        مشروع "التغذية الصحية": NutriLocalis تطوير منتجات غذائية محلية تعتمد على المكونات الطبيعية، مع التركيز على تحسين العادات الغذائية في المجتمع.
                      </p>
                      <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">إقرأ المزيد
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                      </a>
                  </div>
              </div>
              <div class="p-4 lg:w-1/3">
                  <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 font-Cairo">CATEGORY</h2>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 font-Cairo">
                        ArborCommunitas برنامج "زراعة الأشجار"
                      </h1>
                      <p class="leading-relaxed mb-3 font-Cairo">
                        برنامج "زراعة الأشجار": ArborCommunitas مشروع مجتمعي يهدف إلى زراعة الأشجار في المناطق الحضرية والريفية، للمساهمة في تحسين البيئة ومكافحة التغير المناخي.
                      </p>
                      <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">إقرأ المزيد
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script setup>

</script>
