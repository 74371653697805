<template>
<div class="max-w-7xl mx-auto p-4 font-Cairo">
    <div class="text-center max-w-2xl mx-auto">
        <h2
            class="text-3xl font-extrabold  text-gray-800 inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-[#3AAFA9] after:rounded-full">
            الخبراء و المستخدمين</h2>
    </div>

    <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-8 mt-12 max-md:max-w-lg mx-auto" >
        <div class="p-6  shadow rounded-2xl bg-gray-900 border border-gray-300  hover:shadow-2xl transition-all">
            <dl class="space-y-2">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400">عدد الخبراء النشطاء</dt>

                <dd class="text-5xl font-light md:text-6xl text-white">15 خبير</dd>

                <dd class="flex items-center space-x-1 text-sm font-medium text-green-400">
                    <span>08 %</span>

                    <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17.25 15.25V6.75H8.75"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17 7L6.75 17.25"></path>
                    </svg>
                </dd>
            </dl>
        </div>
        <div class="p-6  shadow rounded-2xl bg-gray-900 border border-gray-300  hover:shadow-2xl transition-all">
            <dl class="space-y-2">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400">عدد المستخدمين النشطاء</dt>

                <dd class="text-5xl font-light md:text-6xl text-white">18 مستخدم</dd>

                <dd class="flex items-center space-x-1 text-sm font-medium text-green-400">
                    <span>12 %</span>

                    <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17.25 15.25V6.75H8.75"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17 7L6.75 17.25"></path>
                    </svg>
                </dd>
            </dl>
        </div>
        <div class="p-6  shadow rounded-2xl bg-gray-900 border border-gray-300  hover:shadow-2xl transition-all">
            <dl class="space-y-2">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400">عدد المستخدمين الجدد</dt>

                <dd class="text-5xl font-light md:text-6xl text-white">13 مستخدم</dd>

                <dd class="flex items-center space-x-1 text-sm font-medium text-green-400">
                    <span>16 %</span>

                    <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17.25 15.25V6.75H8.75"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17 7L6.75 17.25"></path>
                    </svg>
                </dd>
            </dl>
        </div>
    </div>
</div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>