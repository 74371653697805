<template>
  <div class="w-64 bg-[#17252A] min-h-screen transition-all flex flex-col justify-between" dir="rtl">
    <!-- Logo -->
    <a href="/expert">
    <img src="../../assets/logosvg.svg" alt="" class="h-[50px] my-1 mx-auto"/>
    </a>
    <!-- <div>
      dfdsfd
    </div> -->
    <!-- Expert header content -->
    <br><br><br><br>
    <!-- <div class="text-white mx-auto text-[1.25rem]"> خاصية الخبير </div> -->
    <ul class="flex flex-col justify-between mb-auto">
      <div>
        <li class="mb-2">
          <router-link
            to="/expert/pending-project"
            class="flex flex-row px-4 py-2 text-white"
            :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/expert/pending-project') }"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
            <span class="font-Cairo">
            المشاريع المعلقة
            </span>
          </router-link>
        </li>
        <li class="mb-2">
          <router-link
            to="/expert/bmc-reservations"
            class="flex flex-row  px-4 py-2 text-white"
            :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/expert/bmc-reservations') }"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
          </svg>
            <span class="font-Cairo">
               حجوزات BMC
               </span>
          </router-link>
        </li>
        <li class="mb-2">
          <router-link
            to="/expert/financial-study-reservations"
            class="flex flex-row px-4 py-2 text-white"
            :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/expert/financial-study-reservations') }"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
          </svg>
          <span class="font-Cairo">
          حجوزات الدراسة المالية
          </span>
          </router-link>
        </li>
        <li class="mb-2">
          <router-link
            to="/expert/free-reservations"
            class="flex flex-row px-4 py-2 text-white"
            :class="{ 'mx-3 bg-[#3aafa9] rounded-[8px]': isActive('/expert/free-reservations') }"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
          </svg>
          <span class="font-Cairo">
          حجوزات حرة
          </span>
          </router-link>
        </li>
      </div>
    </ul>
    <span class="text-white mb-4 text-center font-Cairo ">copyright 2024 <span>@</span></span>
    
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const isActive = (path) => {
  return route.path === path;
};
</script>

<style scoped>
@media (max-width: 768px) {
  .w-64 {
    display: none; /* Hide sidebar in mobile view */
  }
}
</style>











