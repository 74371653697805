<template>
<div class="relative isolate px-6 lg:px-8 font-Cairo flex items-center justify-center md:h-screen p-4" dir="rtl">
  <!-- start effect -->
  <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
    <div
      class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
      style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        " />
  </div>
  <!-- end effect -->

  <!-- start main -->
  <div class="shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)] max-w-full max-md:max-w-full rounded-md p-6">
    <a href="javascript:void(0)"><img src="../assets/11-01.svg" alt="logo" class="w-40 md:mb-4 mb-12" /></a>

    <div class="grid md:grid-cols-2 items-center gap-8">
      <div class="max-md:order-1 lg:min-w-[450px]">
        <img src="../assets/loginBG.svg" class="lg:w-11/12 w-full object-cover" alt="login-image" />
      </div>

      <form class="md:max-w-md w-full mx-auto" @submit.prevent="handleSubmit">
        <div class="mb-12">
          <h3 class="text-4xl font-extrabold text-[#3AAFA9]">تسجيل الدخول</h3>
        </div>

        <div>
          <div class="relative flex items-center" dir="ltr">
            <input name="email" type="email" required ref="emailRef"
              class="w-full text-sm border-b border-gray-300 focus:border-[#3AAFA9] px-2 py-3 outline-none"
              placeholder="أدخل الإيميل" />
            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-2"
              viewBox="0 0 682.667 682.667">
              <!-- SVG content for the email icon -->
            </svg>
          </div>
        </div>

        <div class="mt-8" dir="ltr">
          <div class="relative flex items-center">
            <input name="password" type="password" required ref="passwordRef"
              class="w-full text-sm border-b border-gray-300 focus:border-[#3AAFA9] px-2 py-3 outline-none"
              placeholder="أدخل كلمة المرور" />
            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
              class="w-[18px] h-[18px] absolute right-2 cursor-pointer" viewBox="0 0 128 128">
              <!-- SVG content for the password icon -->
            </svg>
          </div>
        </div>

        <div class="flex flex-wrap items-center justify-between gap-4 mt-6" dir="ltr">
          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox"
              class="h-4 w-4 shrink-0 text-[#3AAFA9] focus:ring-[#2B7A78] border-gray-300 rounded" />
            <label for="remember-me" class="text-gray-800 ml-3 block text-sm">تذكرني</label>
          </div>
          <div>
            <a href="jajvascript:void(0);" class="text-[#3AAFA9] font-semibold text-sm hover:underline">هل نسيت كلمة
              المرور؟</a>
          </div>
        </div>

        <div class="mt-12">
          <button type="submit"
            class="w-full shadow-xl py-2.5 px-5 text-sm font-semibold rounded-md text-white bg-[#3AAFA9] hover:bg-[#2B7A78] focus:outline-none">تسجيل
            الدخول</button>
        </div>
      </form>
    </div>
  </div>
  <!-- end main -->
  <!-- Error Alert Modal -->
  <div v-if="showErrorModal" class="fixed inset-0 font-Cairo text-center z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div
      class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">

            <button class="absolute top-2 right-2" @click="showErrorModal = false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="flex-shrink-0 w-6 h-6">
          <polygon
            points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313">
          </polygon>
        </svg>
      </button>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="mb-4 h-20 w-20 text-red-600 mx-auto">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
    </svg>
            <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">فشل تسجيل الدخول</h2>
            <div class="text-gray-600 mb-8">
              كلمة المرور خاطئة أو ان لم يتم قبول ملف تشرحكم الرجار إصلاح الأخطاء إعادة المحاولة.
            </div>
            <button @click="showErrorModal = false"
              class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border">
              إعادة المحاولة
            </button>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue'

const emailRef = ref(null)
const passwordRef = ref(null)
const showErrorModal = ref(false)

const handleSubmit = () => {
  const email = emailRef.value.value
  const password = passwordRef.value.value
console.log(email);
console.log(password);

  if (email === "admin@gmail.com" && password === "admin") {
    window.location.href = "/admin"
  } else {
    if (email === "expert@gmail.com" && password === "expert") {
      window.location.href = "/expert"
    } else {
      if (email === "user@gmail.com" && password === "user") {
        window.location.href = "/user"
      } else {
        // Show error modal
        showErrorModal.value = true
      }
    }
  }
}
</script>

<style scoped></style>
