<template>
    <!-- navbar --> 
    <!-- <nav class="w-full relative py-4 px-[5%] bg-[#17252A]" dir="ltr">
        <i class="fa-solid fa-user text-[2rem] text-white cursor-pointer" @click="dropdown = !dropdown"></i>
        <div v-if="dropdown" class="absolute bottom-[-6.5rem] bg-gray-700 py-2 px-4 text-white text-[1.25rem] rounded-[10px]">
            <p>Jhon Foe</p>
            <a href="#">الملف الشخصي</a>
            <a href="#">الإعدادات</a>
            <hr>
            
            <a href="#">تسجيل الخروج</a>
        </div>
    </nav> -->
    <!-- body page content --> 
    <div class="mx-auto mt-4 px-4 sm:px-6 lg:px-8"> 
      <!-- component -->
      <br>
      <div class="relative max-w-md mx-auto md:max-w-2xl lg:max-w-4xl min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl mt-16">
          <div class="px-6">
              <div class="flex flex-wrap justify-center">
                  <div class="w-full flex justify-center">
                      <div class="relative">
                          <img src="https://github.com/creativetimofficial/soft-ui-dashboard-tailwind/blob/main/build/assets/img/team-2.jpg?raw=true" class="shadow-xl rounded-full align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-[150px]"/>
                      </div>
                  </div>
                  <div class="w-full text-center mt-20">
                      <div class="flex justify-center lg:pt-4 pt-8 pb-0">
                          <div class="p-3 text-center">
                              <span class="text-xl font-bold block uppercase tracking-wide text-slate-700">29</span>
                              <span class="text-sm text-slate-500">المشاريع المتابعة</span>
                          </div>
                          <!-- <div class="p-3 text-center">
                              <span class="text-xl font-bold block uppercase tracking-wide text-slate-700">2,454</span>
                              <span class="text-sm text-slate-400">Followers</span>
                          </div>
                        
                          <div class="p-3 text-center">
                              <span class="text-xl font-bold block uppercase tracking-wide text-slate-700">564</span>
                              <span class="text-sm text-slate-400">Following</span>
                          </div> -->
                      </div>
                  </div>
              </div>
              <div class="text-center mt-2">
                  <h3 class="text-2xl text-slate-700 font-bold leading-normal mb-1">Salah Eddine Megdoud</h3>
                  <div class="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                      <i class="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>M'sila
                  </div>
              </div>
              <div class="mt-6 py-6 border-t border-slate-200 text-center">
                  <div class="flex flex-wrap justify-center">
                      <div class="w-full px-4">
                          <p class="font-light leading-relaxed text-slate-600 mb-4">An artist of considerable range, Mike is the name taken by Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and records all of his own music, giving it a warm.</p>
                          <!-- <a href="javascript:;" class="font-normal text-slate-700 hover:text-slate-400">Follow Account</a> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- <footer class="bg-white rounded-t-lg shadow dark:bg-[#17252A] mt-4 absolute bottom-0 w-full ">
      <div class="w-full max-w-screen-xl mx-auto p-2   md:py-4">
          <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a class="hover:underline">ElMeg</a>. All Rights Reserved.</span>
      </div>
    </footer> -->
  </template>
  
  