<template>
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-col">
    <div class="lg:w-4/6 mx-auto">
      <div class="flex flex-col text-center items-center w-full mb-20">
        <h1 class="text-3xl font-medium title-font mb-4 text-gray-900 font-Cairo">تفاصيل مشروعك</h1>
        <!-- <p class="lg:w-2/3 mx-auto leading-relaxed text-2xl font-Cairo">هذه قائمة لأفضل خبرائنا ، يعملون جاهدا لمساعدتك على بناء نموذج عمل شركتك ، إحجز موعد مع أحدهم</p> -->
        <div class="w-12 h-1 bg-[#3aafa9] rounded mt-2 mb-4"></div>
      </div>
      <div class="flex flex-col sm:flex-row mt-6">
        <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div class="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <div class="flex flex-col items-center text-center justify-center">
            <h2 class="font-medium title-font mt-4 text-gray-900 text-lg font-Cairo">مقدود أحمد</h2>
            <div class="w-12 h-1 bg-[#3aafa9] rounded mt-2 mb-4"></div>
            <p class="text-base font-Cairo">
              Solaris Domus
              </p>
          </div>
        </div>
        <div class="sm:w-2/3 sm:pr-8 sm:py-8 sm:border-r border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-right">
          <h1 class="text-2xl font-medium title-font mb-4 text-gray-900 font-Cairo">تفاصيل مشروعك</h1>
          <p class="leading-relaxed text-lg mb-4 font-Cairo">
            مشروع "الطاقة الشمسية المنزلية": Solaris Domus تطوير أنظمة طاقة شمسية صغيرة لتزويد المنازل بالطاقة المتجددة، مما يساعد في تقليل الاعتماد على مصادر الطاقة التقليدية. 
            </p>
          <!-- <a class="text-green-500 inline-flex items-center font-Cairo">Learn More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</section>
</template>
  
<script setup>

  </script>
  