<template><!-- Projects section -->
<div id="projects-section"
  class="px-4 py-16 font-Cairo mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" dir="rtl">
  <div class="sm:text-center sm:mx-auto">
    <h2 class="mb-6 font-Cairo text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
      مشاريع محتضنة
    </h2>
    <!-- Cards grid -->
    <div class="grid gap-8 md:grid-cols-3 md:gap-4">
      <!-- Start card -->
      <div
        class="flex flex-col gap-4 border-solid p-6 md:p-4 rounded-xl border border-gray-300 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50 "
        dir="rtl">
        <a href="/" aria-label="Article" title="Happy new Year"
          class="inline-block mb-3 text-2xl font-Cairo  text-center font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">درسني</a>
        <p class="mb-5 font-Cairo text-center text-gray-700">مشروع "درسني"، منصة تعليمية إلكترونية:
          تقدم محتوى تعليمي متنوع ودورات تدريبية في مجالات متعددة، مع التركيز على التعلم عن بُعد ووسائل التعليم
          التفاعلي.</p>
        <hr class="my-1 border-gray-300" />
        <div class="flex items-center gap-2 sm:gap-x-4">
          <div class="flex items-center gap-x-2">
            <img
              src="../../assets/person7.jpg"
              alt="" class="h-12 w-12 rounded-full object-cover" />
            <div>
              <a href="/" aria-label="Author" title="Author"
                class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">حشروف موسى</a>
              <p class="text-sm font-medium leading-4 text-gray-600">صاحب المشروع</p>
            </div>
          </div>
        </div>
      </div>
      <!-- End card -->
      <!-- Start card -->
      <div
      class="flex flex-col gap-4 border-solid p-6 md:p-4 rounded-xl border border-gray-300 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50 "
      dir="rtl">
        <a href="/" aria-label="Article" title="Happy new Year"
          class="inline-block mb-3 text-2xl text-center font-bold font-Cairo leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">مصروف</a>
        <p class="mb-5 text-center font-Cairo  text-gray-700">خدمة "مصروف" للتوصيل:
          منصة تربط بين المتاجر المحلية والمستهلكين، مع التركيز على استخدام وسائل النقل الصديقة للبيئة لتقليل البصمة
          الكربونية.</p>
        <hr class="my-1 border-gray-300" />
        <div class="flex items-center gap-2 sm:gap-x-4">
          <div class="flex items-center gap-x-2">
            <img
              src="../../assets/person9.jpg"
              alt="" class="h-12 w-12 rounded-full object-cover" />
            <div>
              <a href="/" aria-label="Author" title="Author"
                class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">قلمين أيوب</a>
              <p class="text-sm font-medium leading-4 text-gray-600">صاحب المشروع</p>
            </div>
          </div>
        </div>
      </div>
      <!-- End card -->
      <!-- Start card -->
      <div
        class="flex flex-col gap-4 border-solid p-6 md:p-4 rounded-xl border border-gray-300 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50 "
        dir="rtl">
        <a href="/" aria-label="Article" title="Happy new Year"
          class="inline-block mb-3 text-2xl text-center font-bold font-Cairo leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">عناية</a>
        <p class="mb-5 text-center font-Cairo  text-gray-700">تطبيق "عناية" لإدارة الصحة الشخصية:
          يساعد المستخدمين على تتبع صحتهم من خلال ميزات مثل قياس النشاط البدني، متابعة النظام الغذائي، وتقديم نصائح صحية
          شخصية.</p>
        <hr class="my-1 border-gray-300" />
        <div class="flex items-center gap-2 sm:gap-x-4">
          <div class="flex items-center gap-x-2">
            <img
              src="../../assets/person11.jpg"
              alt="" class="h-12 w-12 rounded-full object-cover" />
            <div>
              <a href="/" aria-label="Author" title="Author"
                class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">سقاي كوثر</a>
              <p class="text-sm font-medium leading-4 text-gray-600">صاحب المشروع</p>
            </div>
          </div>
        </div>
      </div>
      <!-- End card -->
       
       <!-- error  -->
      <!-- Start card -->
      <div
      class="flex flex-col gap-4 border-solid p-6 md:p-4 rounded-xl border border-gray-300 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50 "
      dir="rtl">
        <a href="/" aria-label="Article" title="Happy new Year"
          class="inline-block mb-3 text-2xl text-center font-bold font-Cairo leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">مستدام</a>
        <p class="mb-5 text-center font-Cairo  text-gray-700">شركة "مستدام" لتصنيع المنتجات القابلة للتحلل:
          تطوير وتصنيع بدائل مستدامة للمنتجات البلاستيكية، مثل الأكياس والأدوات المنزلية، باستخدام مواد طبيعية وقابلة
          للتحلل.</p>
        <hr class="my-1 border-gray-300" />
        <div class="flex items-center gap-2 sm:gap-x-4">
          <div class="flex items-center gap-x-2">
            <img
              src="../../assets/person10.jpg"
              alt="" class="h-12 w-12 rounded-full object-cover" />
            <div>
              <a href="/" aria-label="Author" title="Author"
                class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">زغلاش صلاح الدين</a>
              <p class="text-sm font-medium leading-4 text-gray-600">صاحب المشروع</p>
            </div>
          </div>
        </div>
      </div>
      <!-- End card -->
      <!-- Start card -->
      <div
        class="flex flex-col gap-4 border-solid p-6 md:p-4 rounded-xl border border-gray-300 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50 "
        dir="rtl">
        <a href="/" aria-label="Article" title="Happy new Year"
          class="inline-block mb-3 text-2xl text-center font-bold font-Cairo leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">تكافل</a>
        <p class="mb-5 text-center font-Cairo  text-gray-700">منصة "تكافل" للمشاريع المجتمعية:
          تتيح للأفراد والمنظمات غير الربحية عرض أفكار مشاريع مجتمعية، وجمع التبرعات، والتواصل مع المتطوعين والمستفيدين.
        </p>
        <hr class="my-1 border-gray-300" />
        <div class="flex items-center gap-2 sm:gap-x-4">
          <div class="flex items-center gap-x-2">
            <img
              src="../../assets/person12.jpg"
              alt="" class="h-12 w-12 rounded-full object-cover" />
            <div>
              <a href="/" aria-label="Author" title="Author"
                class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400">بن حوة لطيفة</a>
              <p class="text-sm font-medium leading-4 text-gray-600">صاحب المشروع</p>
            </div>
          </div>
        </div>
      </div>
      <!-- End card -->
    </div>
  </div>
</div>
</template>


<script setup>

</script>

<style scoped></style>