<template>
<div class="bg-white font-Cairo my-4">
    <div class="max-w-6xl mx-auto">
        <div class="text-center">
            <h2
                class="text-3xl font-extrabold font-Cairo text-gray-800 inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-[#3AAFA9] after:rounded-full">
                الخدمات</h2>
        </div>
        <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-16 max-lg:max-w-3xl max-md:max-w-md mx-auto">
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="94 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">94</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">التوجيه والإرشاد</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="50 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">50</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">التدريب والتطوير</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="61 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">61</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">الوصول إلى التمويل</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="83 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">83</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">الدعم الإداري والقانوني</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="15 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">15</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">المساحات المكتبية الافتراضية</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="76 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">76</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">شبكات العلاقات</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
            <div
                class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative top-0 hover:-top-2 transition-all duration-300">
                <!-- Gauge Component -->
                <div class="relative size-40 w-full h-60 object-cover" dir="auto">
                    <svg class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background Circle (Gauge) -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-100"
                            stroke-width="3" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                        <!-- Gauge Progress -->
                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-orange-600"
                            stroke-width="1" stroke-dasharray="60 100" stroke-linecap="round"></circle>
                    </svg>

                    <!-- Value Text -->
                    <div class="absolute top-1/3 start-1/2 transform -translate-x-1/2 text-center">
                        <span class="text-2xl font-bold text-orange-600">60</span>
                        <span class="text-xs text-orange-600 block">%</span>
                    </div>
                </div>
                <!-- End Gauge Component -->
                <div class="p-6">
                    <h3 class="text-xl font-bold text-gray-800">الدعم التقني</h3>
                    <hr class="my-4" />
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-100 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المطلوبة</span>
                    <span class="flex items-center text-sm font-medium text-gray-900  me-3"><span class="flex w-2.5 h-2.5 bg-orange-600 rounded-full me-1.5 flex-shrink-0"></span>نسبة الخدمات المنجزة</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>