<template>
  <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center items-center w-full mb-20">
              <h1 class="text-3xl font-medium title-font mb-4 text-gray-900 font-Cairo">
                  إدارة المستخدمين
              </h1>
              <!-- <p class="lg:w-2/3 mx-auto leading-relaxed text-2xl font-Cairo">هذه قائمة لأفضل خبرائنا ، يعملون جاهدا لمساعدتك على بناء نموذج عمل شركتك ، إحجز موعد مع أحدهم</p> -->
              <div class="w-12 h-1 bg-[#3aafa9] rounded mt-2 mb-4"></div>
          </div>
          <div class="overflow-x-auto">
        <table class="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th class="px-4 py-2 text-right bg-gray-100 font-Cairo">إسم المستخدم</th>
              <th class="px-4 py-2 text-right bg-gray-100 font-Cairo">الفئة</th>
              <th class="px-4 py-2 text-right bg-gray-100 font-Cairo">إسم المشروع</th>
              <th class="px-4 py-2 text-right bg-gray-100 font-Cairo">الوصف</th>
              <th class="px-4 py-2 text-right bg-gray-100 font-Cairo">رابط</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-t">
              <td class="px-4 py-4 text-right font-Cairo">إسم المشروع</td>
              <td class="px-4 py-4 text-right font-Cairo">CATEGORY</td>
              <td class="px-4 py-4 text-right font-Cairo">إسم المشروع</td>
              <td class="px-4 py-4 text-right font-Cairo">وصف وصف وصف وصف وصف وصف...</td>
              <td class="px-4 py-4 text-right font-Cairo">
                <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">
                  إقرأ المزيد
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                  </svg>
                </a>
              </td>
            </tr>
            <!-- Repeat the structure for additional rows -->
            <tr class="border-t">
              <td class="px-4 py-4 text-right font-Cairo">إسم المشروع</td>
              <td class="px-4 py-4 text-right font-Cairo">CATEGORY</td>
              <td class="px-4 py-4 text-right font-Cairo">إسم المشروع</td>
              <td class="px-4 py-4 text-right font-Cairo">وصف وصف وصف وصف وصف وصف...</td>
              <td class="px-4 py-4 text-right font-Cairo">
                <a href="/expert/project1" class="text-[#3aafa9] inline-flex items-center font-Cairo">
                  إقرأ المزيد
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                  </svg>
                </a>
              </td>
            </tr>
            <!-- Add more rows as needed -->
          </tbody>
        </table>
      </div>
      </div>
  </section>
  </template>
  
  <script setup>
  </script>
  