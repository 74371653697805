<template>
    <nav class="flex flex-row items-center justify-between w-full h-[55px] relative py-[15px] px-[20px] z-10 bg-gray-200 border-b shadow-md" dir="ltr">
      
      <!-- User Profile Icon (visible on all screens) -->
      <div class="w-10 h-10 bg-[#17252A] rounded-full flex items-center justify-center" @click="profileDropdown = !profileDropdown">
        <!-- <i class="fa-solid fa-user text-[1rem] text-white cursor-pointer"></i> -->
        
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" style="color: white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
        

      </div>
  
      <!-- Profile Dropdown Menu -->
      <div v-if="profileDropdown" class="absolute bottom-[-10rem]  start-2 bg-[#17252A] py-2 px-4 text-white text-[1.25rem] rounded-[8px]">
        <p class="text-center text-lg text-white mb-4">John Foe</p>
        <a href="/admin/profile" class="text-center text-lg">
          <p class="text-center text-lg text-white mb-2 font-Cairo ">الملف الشخصي</p>
        </a>
        <a href="#" class="text-center text-lg">
          <p class="text-center text-lg text-white mb-2 font-Cairo ">الإعدادات</p>
        </a>
        <a href="/" class="text-center text-lg">
          <p class="text-center text-lg text-white mb-2 font-Cairo ">تسجيل الخروج</p>
        </a>
      </div>
  
      
  
      <!-- Search Bar -->
      <form class="max-w-md mx-auto md:block text-right">
        <label for="default-search" class="mb-0 text-sm font-medium text-gray-900 sr-only dark:text-white font-Cairo">إبحث</label>
        <div class="relative">
          <div class="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
            <svg class="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
          <input type="search" id="default-search" class=" text-right font-Cairo block w-full p-2 pe-10 text-sm text-white border border-gray-300 rounded-lg bg-gray-50 dark:bg-[#17252A]" placeholder="إبحث" required />
        </div>
      </form>


      <!-- Menu Dropdown Button for Mobile View -->
      <div v-if="isMobile" class="w-10 h-10 bg-[#17252A] rounded-full flex items-center justify-center" @click="menuDropdown = !menuDropdown">
        <i class="fa-solid fa-bars text-[1rem] text-white cursor-pointer"></i> <!-- Mobile menu icon -->
      </div>
    </nav>
    <!-- Mobile Menu Dropdown (Different Elements) -->
    <div v-if="menuDropdown && isMobile" class="flex flex-col items-start w-full h-auto start-0 bg-[#17252A] py-2 px-4 text-white text-[1.25rem]">
        <a href="/admin" class="text-center text-lg mb-2 flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
              <path stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
         </svg>
            <span class="font-Cairo text-center text-lg text-white mb-2">
            إحصائيات
           </span>
        </a>
        <a href="/admin/expert-managment" class="text-center text-lg mb-2 flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
              <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
            <span class="font-Cairo text-center text-lg text-white mb-2">
            إدارة الخبراء
           </span>
        </a>
        <a href="/admin/user-managment" class="text-center text-lg mb-2 flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
              <path stroke-linecap="round" stroke-linejoin="round"
              d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
           </svg>
            <span class="font-Cairo text-center text-lg text-white mb-2">
                إدارة المستخدمين
            </span>
        </a>   
      </div>
</template>
  
<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue';

    const profileDropdown = ref(false);
    const menuDropdown = ref(false);
    const isMobile = ref(window.innerWidth <= 768);

    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    // Listen to window resize to check mobile view
    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });
</script>