<template>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 font-Cairo">حجوزات حرة</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base font-Cairo"></p>
    </div>
    <div class="flex flex-wrap -m-2">
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-start border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center rounded-full"
            src="../../assets/person37.jpg">
          <div class="flex-grow mr-4">
            <h2 class="text-gray-900 title-font font-medium font-Cairo">صاحب المشروع : صلاح الدين</h2>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo">إسم المشروع : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                مشروع "تطبيق تيسير" (EaseApp)
              </p>
            </div>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo"> الوصف : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                تطبيق يسهل الوصول إلى خدمات النقل العمومي، ويقدم معلومات حول المواعيد والأسعار في الوقت الحقيقي.
              </p>
            </div>
            <p class="text-[#2B7A78] mt-1 font-Cairo">islam.megdoude@gmail.com</p>
            <div class="flex flex-row justify-around mt-2">
              <button
              @click="showSuccessModel = true" class="font-Cairo my-2 bg-[#3aafa9] text-white px-2 py-2 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
                مقبول
              </button>
              <button @click="showRejectModel = true"
                class="font-Cairo my-2 bg-[#af3a3a] text-white px-2 py-2 text-lg rounded-lg hover:bg-[#af3a3a] focus:outline-none focus:ring focus:ring-[#af3a3a]">
                مرفوض
              </button>
            </div>
            <div class="p-1 mt-1 border-t border-gray-200">
              <small class="text-gray-500 font-Cairo">منذ 2 ساعات</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-start border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center rounded-full"
            src="../../assets/person38.jpg">
          <div class="flex-grow mr-4">
            <h2 class="text-gray-900 title-font font-medium font-Cairo">صاحب المشروع : صلاح الدين</h2>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo">إسم المشروع : </p>
              <p class="leading-relaxed mb-3 font-Cairo">مشروع "مزرعة الحي" (UrbanFarm)</p>
            </div>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo"> الوصف : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                مشروع لزراعة الخضروات والفواكه في المساحات الصغيرة داخل المدن، مما يوفر منتجات طازجة للسكان.
              </p>
            </div>
            <p class="text-[#2B7A78] mt-1 font-Cairo">islam.megdoude@gmail.com</p>
            <div class="flex flex-row justify-around mt-2">
              <button @click="showSuccessModel = true"
                class="font-Cairo my-2 bg-[#3aafa9] text-white px-2 py-2 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
                مقبول
              </button>
              <button @click="showRejectModel = true"
                class="font-Cairo my-2 bg-[#af3a3a] text-white px-2 py-2 text-lg rounded-lg hover:bg-[#af3a3a] focus:outline-none focus:ring focus:ring-[#af3a3a]">
                مرفوض
              </button>
            </div>
            <div class="p-1 mt-1 border-t border-gray-200">
              <small class="text-gray-500 font-Cairo">منذ 2 ساعات</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-start border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center rounded-full"
            src="../../assets/person39.jpg">
          <div class="flex-grow mr-4">
            <h2 class="text-gray-900 title-font font-medium font-Cairo">صاحب المشروع : صلاح الدين</h2>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo">إسم المشروع : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                مشروع "خدمات صيانة المنازل" (HomeFix)
              </p>
            </div>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo"> الوصف : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                منصة تربط بين سكان الأحياء وعمال الصيانة المهرة، لتسهيل الوصول إلى خدمات الإصلاح والصيانة.
              </p>
            </div>
            <p class="text-[#2B7A78] mt-1 font-Cairo">islam.megdoude@gmail.com</p>
            <div class="flex flex-row justify-around mt-2">
              <button @click="showSuccessModel = true"
                class="font-Cairo my-2 bg-[#3aafa9] text-white px-2 py-2 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
                مقبول
              </button>
              <button @click="showRejectModel = true" 
                class="font-Cairo my-2 bg-[#af3a3a] text-white px-2 py-2 text-lg rounded-lg hover:bg-[#af3a3a] focus:outline-none focus:ring focus:ring-[#af3a3a]">
                مرفوض
              </button>
            </div>
            <div class="p-1 mt-1 border-t border-gray-200">
              <small class="text-gray-500 font-Cairo">منذ 2 ساعات</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-start border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center rounded-full"
            src="../../assets/person40.jpg">
          <div class="flex-grow mr-4">
            <h2 class="text-gray-900 title-font font-medium font-Cairo">صاحب المشروع : صلاح الدين</h2>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo">إسم المشروع : </p>
              <p class="leading-relaxed mb-3 font-Cairo">مشروع "سوق المنتجات المحلية" (LocalMarket)</p>
            </div>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo"> الوصف : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                منصة رقمية تسهل شراء وبيع المنتجات المحلية، مما يعزز الاقتصاد المحلي ويشجع على الاستهلاك المحلي.
              </p>
            </div>
            <p class="text-[#2B7A78] mt-1 font-Cairo">islam.megdoude@gmail.com</p>
            <div class="flex flex-row justify-around mt-2">
              <button @click="showSuccessModel = true"
                class="font-Cairo my-2 bg-[#3aafa9] text-white px-2 py-2 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
                مقبول
              </button>
              <button @click="showRejectModel = true"
                class="font-Cairo my-2 bg-[#af3a3a] text-white px-2 py-2 text-lg rounded-lg hover:bg-[#af3a3a] focus:outline-none focus:ring focus:ring-[#af3a3a]">
                مرفوض
              </button>
            </div>
            <div class="p-1 mt-1 border-t border-gray-200">
              <small class="text-gray-500 font-Cairo">منذ 2 ساعات</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-start border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center rounded-full"
            src="../../assets/person41.jpg">
          <div class="flex-grow mr-4">
            <h2 class="text-gray-900 title-font font-medium font-Cairo">صاحب المشروع : صلاح الدين</h2>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo">إسم المشروع : </p>
              <p class="leading-relaxed mb-3 font-Cairo">مشروع "تطبيق الرعاية الصحية" (HealthBuddy)</p>
            </div>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo"> الوصف : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                تطبيق يقدم استشارات طبية عن بُعد، ويتيح للمستخدمين الوصول إلى الأطباء بسهولة.
              </p>
            </div>
            <p class="text-[#2B7A78] mt-1 font-Cairo">islam.megdoude@gmail.com</p>
            <div class="flex flex-row justify-around mt-2">
              <button @click="showSuccessModel = true"
                class="font-Cairo my-2 bg-[#3aafa9] text-white px-2 py-2 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
                مقبول
              </button>
              <button @click="showRejectModel = true"
                class="font-Cairo my-2 bg-[#af3a3a] text-white px-2 py-2 text-lg rounded-lg hover:bg-[#af3a3a] focus:outline-none focus:ring focus:ring-[#af3a3a]">
                مرفوض
              </button>
            </div>
            <div class="p-1 mt-1 border-t border-gray-200">
              <small class="text-gray-500 font-Cairo">منذ 2 ساعات</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-start border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center rounded-full"
            src="../../assets/person42.jpg">
          <div class="flex-grow mr-4">
            <h2 class="text-gray-900 title-font font-medium font-Cairo">صاحب المشروع : صلاح الدين</h2>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo">إسم المشروع : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                شروع "إدارة النفايات الذكية" (SmartWaste)
              </p>
            </div>
            <div class="text-gray-500 mt-1 font-Cairo">
              <p class="text-gray-900 title-font font-medium font-Cairo"> الوصف : </p>
              <p class="leading-relaxed mb-3 font-Cairo">
                نظام مبتكر لجمع وفرز النفايات في المدن، يعتمد على تطبيقات الهواتف الذكية لتحفيز المواطنين على المشاركة.
              </p>
            </div>
            <p class="text-[#2B7A78] mt-1 font-Cairo">islam.megdoude@gmail.com</p>
            <div class="flex flex-row justify-around mt-2">
              <button @click="showSuccessModel = true"
                class="font-Cairo my-2 bg-[#3aafa9] text-white px-2 py-2 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
                مقبول
              </button>
              <button @click="showRejectModel = true"
                class="font-Cairo my-2 bg-[#af3a3a] text-white px-2 py-2 text-lg rounded-lg hover:bg-[#af3a3a] focus:outline-none focus:ring focus:ring-[#af3a3a]">
                مرفوض
              </button>
            </div>
            <div class="p-1 mt-1 border-t border-gray-200">
              <small class="text-gray-500 font-Cairo">منذ 2 ساعات</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!-- showSuccessModel  -->
      <div v-if="showSuccessModel" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">
        <button class="absolute top-2 right-2" @click="showSuccessModel = false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="flex-shrink-0 w-6 h-6">
            <polygon
              points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313">
            </polygon>
          </svg>
        </button>
        <svg class="mb-4 h-20 w-20 text-[#3aafa9] mx-auto" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd" />
        </svg>

        <h2 class="text-2xl mb-4 text-gray-800 text-center font-Cairo">تم القبول بنجاح</h2>
        <div class="text-gray-600 mb-8 font-Cairo">سيتم ضبط موعد للبدء في عملية المرافقة عبر تقنية التحاضر عن بعد</div>
        <button @click="showSuccessModel = false"
          class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100  border font-Cairo">
          ارجع الى الصفحة الرئيسية
        </button>
      </div>
    </div>
    <!-- showRejectModel  -->
    <div v-if="showRejectModel" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">
        <button class="absolute top-2 right-2" @click="showRejectModel = false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="flex-shrink-0 w-6 h-6">
            <polygon
              points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313">
            </polygon>
          </svg>
        </button>
        <svg xmlns="http://www.w3.org/2000/svg" class="mb-4 h-20 w-20 text-[#af3a3a] mx-auto" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>

        <h2 class="text-2xl mb-4 text-gray-800 text-center font-Cairo">تم الرفض بنجاح</h2>
        
        <button @click="showRejectModel = false"
          class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100  border font-Cairo">
          ارجع الى الصفحة الرئيسية
        </button>
      </div>
    </div>
</section>
</template>

<script setup>
import { ref } from 'vue'
const showSuccessModel = ref(false)
const showRejectModel = ref(false)

</script>