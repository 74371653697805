<template>
    <nav class="flex flex-row items-center justify-between w-full h-[55px] relative py-[15px] px-[20px] z-10 bg-gray-200 border-b shadow-md" dir="ltr">
      
      <!-- User Profile Icon (visible on all screens) -->
      <div class="w-10 h-10 bg-[#17252A] rounded-full flex items-center justify-center" @click="profileDropdown = !profileDropdown">
        <!-- <i class="fa-solid fa-user text-[1rem] text-white cursor-pointer"></i> -->
        
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" style="color: white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
        

      </div>
  
      <!-- Profile Dropdown Menu -->
      <div v-if="profileDropdown" class="absolute bottom-[-10rem]  start-2 bg-[#17252A] py-2 px-4 text-white text-[1.25rem] rounded-[8px]">
        <p class="text-center text-lg text-white mb-4 font-Cairo">أحمد
        </p>
        <a href="/user/user-profile" class="text-center text-lg">
          <p class="text-center text-lg text-white mb-2 font-Cairo ">الملف الشخصي</p>
        </a>
        <a href="#" class="text-center text-lg">
          <p class="text-center text-lg text-white mb-2 font-Cairo">الإعدادات</p>
        </a>
        <a href="/" class="text-center text-lg">
          <p class="text-center text-lg text-white mb-2 font-Cairo">تسجيل الخروج</p>
        </a>
      </div>
  
      
  
      <!-- Search Bar -->
      <form class="max-w-md mx-auto md:block text-right">
        <label for="default-search" class="mb-0 text-sm font-medium text-gray-900 sr-only dark:text-white font-Cairo">إبحث</label>
        <div class="relative">
          <div class="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
            <svg class="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
          <input type="search" id="default-search" class=" text-right font-Cairo block w-full p-2 pe-10 text-sm text-white border border-gray-300 rounded-lg bg-gray-50 dark:bg-[#17252A]" placeholder="إبحث" required />
        </div>
      </form>



      <!-- Menu Dropdown Button for Mobile View -->
      <div v-if="isMobile" class="w-10 h-10 bg-[#17252A] rounded-full flex items-center justify-center" @click="menuDropdown = !menuDropdown">
        <i class="fa-solid fa-bars text-[1rem] text-white cursor-pointer"></i> <!-- Mobile menu icon -->
      </div>
    </nav>
    <!-- Mobile Menu Dropdown (Different Elements) -->
    <div v-if="menuDropdown && isMobile" class="flex flex-col items-center w-full h-auto start-0 bg-[#17252A] py-2 px-4 text-white text-[1.25rem]">
        <a href="/user/user-project-info" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            تفاصيل مشروعك
            </span>
        </a>
        <a href="/user/user-reserve-build-bmc" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            بناء نموذج العمل التجاري BMC
            </span>
        </a>
        <a href="/user/user-reserve-financial-study" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            اعداد الدراسة المالية
            </span>
        </a>
        <a href="/user/user-label" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            الحصول على وسم لابل
            </span>
        </a>
        <a href="/user/user-londa" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            تسجيل براءة اختراع، حماية الملكية الفكرية
            </span>
        </a>
        <a href="/user/user-finance" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            طلب التمويل
            </span>
        </a>
        <a href="/user/user-free-expert" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            حجز موعد مع خبير
          </span>
        </a>
        <a href="/user/user-visual-identity" class="text-center text-lg mb-2 flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z" />
          </svg>
          <span class="font-Cairo text-center text-lg text-white mb-2">
            بناء الهوية البصرية
          </span>
        </a>
      </div>
</template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  
  const profileDropdown = ref(false);
  const menuDropdown = ref(false);
  const isMobile = ref(window.innerWidth <= 768);
  
  const handleResize = () => {
    isMobile.value = window.innerWidth <= 768;
  };
  
  // Listen to window resize to check mobile view
  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });
  
  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });
  </script>