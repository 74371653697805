<template>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center items-center w-full mb-20">
      <h1 class="text-3xl font-medium title-font mb-4 text-gray-900 font-Cairo">الملكية الفكرية</h1>
      <!-- <p class="lg:w-2/3 mx-auto leading-relaxed text-2xl font-Cairo">هذه قائمة لأفضل خبرائنا ، يعملون جاهدا لمساعدتك على بناء نموذج عمل شركتك ، إحجز موعد مع أحدهم</p> -->
      <div class="w-12 h-1 bg-[#3aafa9] rounded mt-2 mb-4"></div>
    </div>
    <div class="flex flex-row justify-center sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div class="p-4 md:w-1/3 flex">
        <div class="w-12 h-12 inline-flex items-center justify-center rounded-full   text-[#3aafa9] mb-4 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
        </div>

        <div class="flex-grow pr-6">
          <h2 class="text-gray-900 text-lg title-font font-medium mb-2 font-Cairo">ONDA</h2>
          <div x-data="{ OpenOnda: false }" class="font-Cairo">
            <!-- Collapsible content -->
            <p class="leading-relaxed text-base" :class="{ 'line-clamp-3': !OpenOnda }">
              ما هي العلامة التجارية؟<br>
              العلامة التجارية هي العلامة التي تُستخدم لتمييز أسماء المنتجات أو الخدمات التي تقدّمها شركة ما عن أسماء
              منتجات وخدمات منافسيها، وتساعد العلامة التجارية المستهلك على اختيار المنتج أو الخدمة التي تلبّي احتياجاته.
              <br>
              وتضم العلامات التي يمكن تمثيلها بصورة غرافيكية، بما في ذلك: الكلمات، أسماء الأشخاص، الأحرف، الأرقام،
              الرسومات، الصور، الشعارات، التوقيعات، الألوان، أو مجموعة من هذه الأشكال المختلفة.
              <br>
              أمّا الأشكال التالية فلا تُعتبر علامات تجارية ولا يمكن تسجيلها كعلامات تجارية:
              <br>
              العلامات التي تستخدم للإشارة إلى المنتج أو وصفه.
              العلامات التي قد تضلّل المستهلكين بشأن طبيعة المنتج، جودته، خصائصه أو أصله.
              العلامات التي تتعارض مع النظام العام أو الأخلاق الحميدة.
              العلامات التي تحتوي على أو تقلّد العلامات الرسمية للدولة.
              العلامات التي تمثّل شكل المنتج أو تغليفه إذا كان هذا الشكل مرتبطًا مباشرة بطبيعته أو وظيفته.
              العلامات التي تتألّف حصرًا أو جزئيًا من إشارة جغرافية.
            </p>

            <!-- Read more button -->
            <a href="#" @click="OpenOnda = !OpenOnda" class="text-[#3aafa9] inline-flex items-center mt-5">
              <span> {{ !OpenOnda ? 'إقرأ المزيد' : 'إقرأ أقل' }}</span>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-5" :class="{
                  'rotate-90': OpenOnda,
                  '-rotate-90 ': !OpenOnda
                }">
                }">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>
            </a>
          </div>

        </div>
      </div>
      <div class="p-4 md:w-1/3 flex">
        <div
          class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-z  text-[#3aafa9] mb-4 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-6 me-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
        </div>
        <div class="flex-grow pr-6">
          <h2 class="text-gray-900 text-lg title-font font-medium mb-2 font-Cairo">INAPI</h2>
          <div x-data="{ OpenInapi: false }" class="font-Cairo">
            <!-- Collapsible content -->
            <p class="leading-relaxed text-base" :class="{ 'line-clamp-3': !OpenInapi }">
              ما هي براءة الاختراع؟<br>
              براءة الاختراع هي وثيقة قانونية تمنح للمخترع حقوق الملكية الصناعية، وتصدر رسميًا من قبل الجهة المختصة (وهي
              المعهد الوطني الجزائري للملكية الصناعية INAPI).
              <br>

              تحمي براءة الاختراع الابتكارات والاختراعات، وتمنح حاملها حقوق حصرية لمدة عشرين عامًا من تاريخ تقديم طلب
              الحماية، شريطة سداد الأقساط السنوية، وعلى أراضي محددة.
              <br>

              كيف تحصل على براءة اختراع في الجزائر؟
              يمكنك تقديم طلب براءة الاختراع عبر اتّباع الخطوات التالية:
              <br>
              افتح حسابًا على موقع الويب الخاص بـ INAPI ،أوسجّل الدخول إلى حسابك إذا كان لديك حساب بالفعل.
              انقر فوق قسم البراءات في لوحة التحكم، ثم انقر فوق “تقديم جديد”.
              اِملأ الحقول المطلوبة بالمعلومات اللازمة.
              أدخل الوثائق المطلوبة لتقديم طلب البراءة، مثل: وصف مفصّل باللغة الوطنية مترجم إلى نسختين باللغة الفرنسية،
              ملخّص الوصف المفصّل، والرسومات إذا كانت مطلوبة.
              اطبع نموذج تقديم الطلب في أربع نسخ.
              ادفع حقوق التسجيل في فرع للبنك الوطني الجزائري (CNEP) على الأراضي الوطنية وقم بإيداعه في حساب INAPI رقم
              100
              00 13733 مع المفتاح 7، كما يمكنك إجراء الدفع بواسطة صكّ بريدي مباشرة في INAPI.
              وقّع النسخ الأربعة المطبوعة من الطلب، وأحضر المستندات المطلوبة.
              قدّم الملف في INAPI في الجزائر أو في الفرع الأقرب إلى مكان إقامتك.
            </p>

            <!-- Read more button -->
            <a href="#" @click="OpenInapi = !OpenInapi" class="text-[#3aafa9] inline-flex items-center mt-5">
              <span> {{ !OpenInapi ? 'إقرأ المزيد' : 'إقرأ أقل' }}</span>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-5" :class="{
                  'rotate-90': OpenInapi,
                  '-rotate-90 ': !OpenInapi
                }">
                }">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<section class="text-gray-600 body-font relative">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 font-Cairo">تواصل معنا</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base font-Cairo">
        تواصل مع الخبير لبدء اجراءات التسجيل
      </p>
    </div>
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="name" class="leading-7 text-sm text-gray-600 font-Cairo">الإسم الكامل</label>
            <input type="text" id="name" name="name"
              class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#3aafa9] focus:bg-white focus:ring-2 focus:ring-[#3aafa9] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-1/2">
          <div class="relative">
            <label for="email" class="leading-7 text-sm text-gray-600 font-Cairo">البريد الإلكتروني</label>
            <input type="email" id="email" name="email"
              class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#3aafa9] focus:bg-white focus:ring-2 focus:ring-[#3aafa9] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
        </div>
        <div class="p-2 w-full">
          <div class="relative">
            <label for="message" class="leading-7 text-sm text-gray-600 font-Cairo">الرسالة</label>
            <textarea id="message" name="message"
              class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#3aafa9] focus:bg-white focus:ring-2 focus:ring-[#3aafa9] h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
        <div class="p-2 w-full">
          <button @click="showSuccessModel = true"
            class="flex mx-auto text-white bg-[#3aafa9] border-0 py-2 px-8 focus:outline-none hover:bg-[#3aafa9c0] rounded text-lg font-Cairo">إرسل</button>
        </div>
        <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
          <a class="text-[#3aafa9] font-Cairo">khotwaincub@gmail.com</a>
          <p class="leading-normal my-5">
          </p>
          <span class="inline-flex">
            <a class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
                viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="ml-4 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
                viewBox="0 0 24 24">
                <path
                  d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">
                </path>
              </svg>
            </a>
            <a class="ml-4 text-gray-500">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a class="ml-4 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
                viewBox="0 0 24 24">
                <path
                  d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z">
                </path>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- showTempModel  -->
  <div v-if="showSuccessModel" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div
      class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">
      <button class="absolute top-2 right-2" @click="showSuccessModel = false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="flex-shrink-0 w-6 h-6">
          <polygon
            points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313">
          </polygon>
        </svg>
      </button>
      <svg class="mb-4 h-20 w-20 text-[#3aafa9] mx-auto" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd" />
      </svg>
      <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
        </svg> 
        <svg xmlns="http://www.w3.org/2000/svg" class="mb-4 h-20 w-20 text-[#ffc234] mx-auto" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
        </svg>-->

      <h2 class="text-2xl mb-4 text-gray-800 text-center font-Cairo">تم الإرسال بنجاح</h2>
      <div class="text-gray-600 mb-8 font-Cairo">
        شكرا لك ، سيتم الرد عليك في اقرب وقت ممكن
      </div>
      <button @click="showSuccessModel = false"
        class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100  border font-Cairo">
        ارجع الى الصفحة الرئيسية
      </button>
    </div>
  </div>
</section>
</template>

<script setup>
import { ref } from 'vue'
const showSuccessModel = ref(false)
</script>