<template>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex flex-col text-center items-center w-full mb-20">
      <h1 class="text-3xl font-medium title-font mb-4 text-gray-900 font-Cairo">خطوات التحصل على لابل 
      </h1>
      <p class="lg:w-2/3 mx-auto mb-1 leading-relaxed text-2xl font-Cairo">تعرف على خطوات الحصول على وسم لابل مشروع مبتكر، او لابل مؤسسة ناشئة</p>
      <div class="w-12 h-1 bg-[#3aafa9] rounded mt-2 mb-4"></div>
    </div>
    <div class="flex items-center justify-center px-2 flex-wrap w-full">
      <div class="md:w-4/6 lg:w-1/2 md:py-6">
        <div class="flex relative pb-12">
          <p class="leading-relaxed font-Cairo text-sm">
            ملخص لمنح العلامة في الجزائر<br>
يتم منح العلامة من قبل اللجنة الوطنية متكونة من 14 عضوا يمثلون وزارات مختلفة وخبراء خارجيين برئاسة ممثل الوزير المكلف بالمؤسسات الناشئة. مهمتها هي:
<br>
منح علامة “مؤسسة ناشئة ” للشركات المبتكرة الشابة
منح علامة “المشاريع المبتكرة” للمبتدئين في المشاريع المبتكرة الذين لم ينشئوا شركة بعد
منح علامة ” حاضنة “
دراسة الطلبات المقدمة بعد رفض منح علامات “مؤسسة ناشئة ” و “المشاريع المبتكرة” و ” حاضنة “
الهدف من وضع العلامات في الجزائر هو الاستفادة من المزايا التالية:
<br>
الإعفاء الممنوح من حيث TAP و IBS و IRG و IFU وضريبة القيمة المضافة على المشتريات المخصصة للاستثمارات
إمكانية الحصول على تمويل من صندوق الاستثمار العمومي المخصص للشركات الناشئة “الصندوق الجزائري للمؤسسات الناشئة”
وذلك من خلال الشروط التالية:
<br>
علامة مؤسسة ناشئة
تعتبر “مؤسسة ناشئة ” بموجب القانون الجزائري المؤسسة التي تستوفي المعايير التالية:
<br>
يجب ألا تكون الشركة موجودة منذ أكثر من ثماني (8) سنوات.
يجب أن يعتمد نموذج عمل الشركة على المنتجات أو الخدمات أو نموذج الأعمال أو أي مفهوم مبتكر آخر.
يجب ألا يتجاوز حجم المبيعات السنوي المبلغ الذي حددته اللجنة الوطنية (تم تحديده لعام 2021 بمبلغ 500.000.000 دينار جزائري أو 50 مليار سنتيم) ؛
يجب أن يحتفظ رأس المال بنسبة 50٪ على الأقل من قبل أشخاص طبيعيين أو صناديق استثمار معتمدة أو شركات أخرى تحمل علامة “مؤسسة ناشئة ” ؛
يجب أن تكون إمكانات تسارع نمو الشركة كبيرة بما يكفي ؛
يجب ألا يزيد عدد موظفي الشركة عن 250 موظفًا.
يتعين على الشركة الراغبة في الحصول على علامة ” مؤسسة ناشئة ” في الجزائر تقديم طلب عبر البوابة الإلكترونية الوطنية للشركات الناشئة مصحوبة بالمستندات التالية:
<br>
مستخرج من السجل التجاري وبطاقة التعريف الضريبية (NIF) والإحصائية (NIS).
نسخة من النظام القانوني للشركة.
شهادة عضوية في الصندوق الوطني للتأمين الاجتماعي (CNAS) مع قائمة بأسماء الموظفين.
شهادة عضوية في الصندوق الوطني للضمان الاجتماعي لغير العاملين (CASNOS)
نسخة من البيانات المالية للسنة الحالية (الميزانية المحاسبية وقائمة الدخل … الخ)
خطة عمل مفصلة.
المؤهلات والخبرات العلمية والفنية لموظفي الشركة.
أي سند ملكية فكرية وأي جوائز أو جوائز تم الحصول عليها.
في مايو 2022، أربعة (04) معايير يمكن أن تثبت الطبيعة المبتكرة لشركة ناشئة، بالإضافة إلى المعايير المذكورة أعلاه. 
<br>
لا يُطلب من الشركات الناشئة تلبية الشروط الأربعة (المعايير) في نفس الوقت، معيار واحد يكفي لإثبات الطبيعة المبتكرة لشركة ناشئة وبالتالي الحصول على علامة ” مؤسسة ناشئة ” وهي:
<br>
نفقات الشركة الناشئة في البحث والتطوير (إذا أنفقت الشركة الناشئة 15٪ من حجم أعمالها على البحث والتطوير)
وجود 50 ٪ أو أكثر من الأعضاء المؤسسين حاصلين على درجة الدكتوراه 
الملكية الفكرية (إذا كان لدى الشركة الناشئة براءة مسجل على المستويين الوطني والدولي)
عرض نموذج أولي لابتكار الشركة الناشئة. يمكن تقديم هذا النموذج الأولي في عدة أشكال، وهي: منصة إلكترونية بنسخة نهائية أو تجريبية، أو نموذج أولي للمنتج إذا كان صناعيًا، أو رابط لتطبيق الشركة المعنية، أو فيديو توضيحي.
يتم منح علامة “المؤسسة الناشئة” للشركة لمدة أربع (4) سنوات قابلة للتجديد مرة واحدة (1).

<br>
<br>


المزايا الضريبية
<br>
تُعفى الشركات التي تحمل علامة “مؤسسة ناشئة” من الضريبة على النشاط المهني (TAP) أو ضريبة الدخل العامة (IRG) أو ضريبة دخل الشركات (IBS) لمدة أربع (4) سنوات، من تاريخ الحصول على “علامة مؤسسة ناشئة” مع سنة إضافية واحدة (1) في حالة التجديد. كما أنهم معفون من ضريبة القيمة المضافة ويخضعون لرسوم جمركية بنسبة 5٪ للمعدات المقتناة التي تدخل مباشرة في تجسيد مشاريعهم الاستثمارية. (المادة 86 من قانون المالية 2021 المعدلة للمادة 33 من قانون المالية التكميلي 2020).
<br>
علامة “مشروع مبتكر”
يمكن لأي شخص طبيعي أو مجموعة من الأشخاص الطبيعيين المطالبة بعلامة “المشاريع المبتكرة” لأي مشروع يتعلق بالابتكار في الجزائر.
<br>
يتعين على الشخص الراغب في الحصول على علامة “ مشروع مبتكر “ تقديم طلب عبر البوابة الإلكترونية الوطنية للشركات الناشئة مصحوبًا بالمستندات التالية:
          </p>
        </div>
      </div>
      <div class="md:w-3/6 lg:w-1/2">
			<img src="../../assets/labelpic.svg" alt="" class="p-6">
		</div>
    </div>
  </div>
</section>
</template>
  
<script setup>

  </script>