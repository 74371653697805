<template>
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center items-center w-full mb-20">
      <h1 class="text-3xl font-medium title-font mb-4 text-gray-900 font-Cairo">قائمة خبرائنا الماليين 
      </h1>
      <p class="lg:w-2/3 mx-auto mb-1 leading-relaxed text-2xl font-Cairo">هذه قائمة لأفضل خبرائنا الماليين ، يعملون جاهدا لمساعدتك على طلب تمويل لمشروعك ، إحجز موعد مع أحدهم</p>
      <div class="w-12 h-1 bg-[#3aafa9] rounded mt-2 mb-4"></div>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person17.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بن قريش مالك
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">
              مختص في الدراسة المالية
              </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">
              

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person18.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بن زكري طارق
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">مختص في الدراسة المالية
              </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person19.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بن يوسف خالد
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">
              مختص في الدراسة المالية
            </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person20.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بن قاسم سليم
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">
              مختص في الدراسة المالية
              </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person29.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بن ناصر ريان
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">
              مختص في الدراسة المالية
              </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">
              

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person30.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بوعبد الله سامي
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">مختص في الدراسة المالية
              </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person31.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              بن صابر عادل
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">
              مختص في الدراسة المالية
            </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
      <div class="p-4 w-full sm:w-1/2 lg:w-1/4 md:w-1/2">
        <div class="h-full flex flex-col items-center text-center">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src="../../assets/person32.jpg">
          <div class="w-full">
            <h2 class="title-font font-medium text-lg text-gray-900 font-Cairo">
              عامر زغيمي
              </h2>
            <h3 class="text-gray-500 mb-3 font-Cairo">
              مختص في الدراسة المالية
              </h3>
            <span class="inline-block h-1 w-10 rounded bg-[#3aafa9] mt-2"></span>
            <p class="mb-4 font-Cairo">

              </p>
            <button @click="showTempModel = true" class="bg-[#3aafa9] text-white px-4 py-2 rounded font-Cairo">تواصل مع الخبير</button>
          </div>
        </div>
      </div>
    </div>
    <!-- showTempModel  -->
    <div v-if="showTempModel" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">
        <button class="absolute top-2 right-2" @click="showTempModel = false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="flex-shrink-0 w-6 h-6">
            <polygon
              points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313">
            </polygon>
          </svg>
        </button>
        <!-- <svg class="mb-4 h-20 w-20 text-[#3aafa9] mx-auto" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
        </svg> -->
        <svg xmlns="http://www.w3.org/2000/svg" class="mb-4 h-20 w-20 text-[#ffc234] mx-auto" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
        </svg>

        <h2 class="text-2xl mb-4 text-gray-800 text-center font-Cairo">الرجاء انتضار موافقة الخبير</h2>
        <div class="text-gray-600 mb-8 font-Cairo">
          شكرا لك ، سيتم الرد عليك في اقرب وقت ممكن
        </div>
        <button @click="showTempModel = false"
          class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100  border font-Cairo">
          ارجع الى الصفحة الرئيسية
        </button>
      </div>
    </div>
  </div>
</section>
</template>
  
<script setup>
import { ref } from 'vue'
const showTempModel = ref(false)
</script>