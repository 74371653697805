<template>
<div class="relative isolate px-6 lg:px-8 font-Cairo flex items-center justify-center min-h-screen p-4" dir="rtl">
  <!-- start effect -->
  <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
    <div
      class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
      style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        " />
  </div>
  <!-- end effect -->

  <!-- start main -->
  <div class="shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)] max-w-full max-md:max-w-full rounded-md">
    <div
      class="relative items-center flex flex-col gap-8 lg:gap-10 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] mx-auto w-full max-w-7xl px-10 py-12 lg:py-16  rounded-lg"
      dir="rtl">
      <!-- Logo Positioned in Top Right -->
      <div class="absolute top-0 right-0 m-4">
        <img src="../assets/11-01.svg" alt="logo" class="w-28" />
      </div>
      <div class=" w-full md:h-1/5 pt-8 items-center justify-center ">

        <!-- stepper start -->
        <div class="mx-auto px-0 w-full">
          <ul aria-label="Steps" class="items-center text-gray-600 font-medium flex">
            <li v-for="(item, idx) in stepsItems" :key="idx" :aria-current="currentStep === idx + 1 ? 'step' : false"
              class="flex-1 last:flex-none flex gap-x-0.5 sm:gap-x-2  items-center">
              <div class="flex items-center flex-col gap-x-2">
                <div :class="{
                  'w-6 h-6 sm:w-8 sm:h-8 rounded-full border-2 flex-none flex items-center justify-center': true,
                  'bg-[#3AAFA9] border-[#3AAFA9]': currentStep > idx + 1,
                  'border-[#3AAFA9]': currentStep === idx + 1,
                  'border-gray-300': currentStep < idx + 1
                }">
                  <span :class="{ 'hidden': currentStep > idx + 1, 'text-[#3AAFA9]': currentStep === idx + 1 }">
                    {{ idx + 1 }}
                  </span>
                  <svg v-if="currentStep > idx + 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1.5" stroke="currentColor" class="w-5 h-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </div>
              </div>
              <div class="flex items-center h-auto">
                <h3 :class="{ 'text-xs sm:text-sm': true, 'text-[#3AAFA9]': currentStep === idx + 1 }">
                  {{ item }}
                </h3>
              </div>

              <hr v-if="idx < stepsItems.length - 1" :class="{
                'mr-2 w-full border block': true,
                'hidden': idx === stepsItems.length,
                'border-[#3AAFA9]': currentStep > idx + 1,
                'border-gray-300': currentStep <= idx + 1
              }" />

            </li>
          </ul>
        </div>
        <!-- end stepper -->
      </div>
      <!-- Content -->
      <div class="flex flex-col gap-8 lg:w-full" dir="rtl">
        <!-- start Step Content -->
        <!-- step 1 start -->
        <div v-if="currentStep === 1" class="grid grid-cols-1 lg:grid-cols-2 gap-10" dir="auto">
          <div class="max-h-80">
            <img src="https://readymadeui.com/management-img.webp" alt="Image"
              class="rounded-md object-cover w-full h-full" />
          </div>
          <div dir="rtl">
            <h2 class="text-3xl font-extrabold text-[#3AAFA9] mb-4">نحن سعداء جدًا بانضمامك إلى منصتنا الرقمية! </h2>
            <p class="text-gray-600 text-sm leading-6">
              لقد اتخذت خطوة مهمة نحو تطوير أفكارك وتنميتها، ونحن هنا لنكون شريكًا موثوقًا وداعمًا لك في كل خطوة من رحلتك الريادية. من خلال منصتنا، ستتمكن من الاستفادة من الموارد، الأدوات، والخبرات التي نقدمها لرواد الأعمال مثلك.
            </p>
            <ul class="list-disc text-sm text-gray-600 space-y-2 pl-4 mt-6">
              <li>سواء كنت تسعى إلى بناء مشروع جديد أو توسيع مشروعك الحالي، نحن هنا لتقديم الدعم اللازم، والتواصل مع شبكة من الخبراء، والشركاء الذين يمكنهم مساعدتك في الوصول إلى أهدافك.</li>
              <li>نتمنى لك تجربة مثمرة وملهمة، ونتطلع إلى رؤية نجاحاتك تتحقق!</li>
              <li>إذا كان لديك أي استفسار أو تحتاج إلى مساعدة، لا تتردد في التواصل معنا.</li>
            </ul>
          </div>
        </div>
        <!-- end step 1 -->
        <!-- step 2 start -->
        <div v-if="currentStep === 2" class="-mx-4 flex flex-wrap">
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                الإسم
              </label>
              <div class="relative">
                <input type="text" placeholder="الإسم"
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
                <span class="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M3.72039 12.8864C4.50179 12.105 5.5616 11.666 6.66667 11.666H13.3333C14.4384 11.666 15.4982 12.105 16.2796 12.8864C17.061 13.6678 17.5 14.7276 17.5 15.8327V17.4993C17.5 17.9596 17.1269 18.3327 16.6667 18.3327C16.2064 18.3327 15.8333 17.9596 15.8333 17.4993V15.8327C15.8333 15.1696 15.5699 14.5338 15.1011 14.0649C14.6323 13.5961 13.9964 13.3327 13.3333 13.3327H6.66667C6.00363 13.3327 5.36774 13.5961 4.8989 14.0649C4.43006 14.5338 4.16667 15.1696 4.16667 15.8327V17.4993C4.16667 17.9596 3.79357 18.3327 3.33333 18.3327C2.8731 18.3327 2.5 17.9596 2.5 17.4993V15.8327C2.5 14.7276 2.93899 13.6678 3.72039 12.8864Z"
                        fill="#9CA3AF" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.0007 3.33268C8.61994 3.33268 7.50065 4.45197 7.50065 5.83268C7.50065 7.21339 8.61994 8.33268 10.0007 8.33268C11.3814 8.33268 12.5006 7.21339 12.5006 5.83268C12.5006 4.45197 11.3814 3.33268 10.0007 3.33268ZM5.83398 5.83268C5.83398 3.5315 7.69946 1.66602 10.0007 1.66602C12.3018 1.66602 14.1673 3.5315 14.1673 5.83268C14.1673 8.13387 12.3018 9.99935 10.0007 9.99935C7.69946 9.99935 5.83398 8.13387 5.83398 5.83268Z"
                        fill="#9CA3AF" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                اللقب
              </label>
              <div class="relative">
                <input type="text" placeholder="اللقب"
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
                <span class="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M3.72039 12.8864C4.50179 12.105 5.5616 11.666 6.66667 11.666H13.3333C14.4384 11.666 15.4982 12.105 16.2796 12.8864C17.061 13.6678 17.5 14.7276 17.5 15.8327V17.4993C17.5 17.9596 17.1269 18.3327 16.6667 18.3327C16.2064 18.3327 15.8333 17.9596 15.8333 17.4993V15.8327C15.8333 15.1696 15.5699 14.5338 15.1011 14.0649C14.6323 13.5961 13.9964 13.3327 13.3333 13.3327H6.66667C6.00363 13.3327 5.36774 13.5961 4.8989 14.0649C4.43006 14.5338 4.16667 15.1696 4.16667 15.8327V17.4993C4.16667 17.9596 3.79357 18.3327 3.33333 18.3327C2.8731 18.3327 2.5 17.9596 2.5 17.4993V15.8327C2.5 14.7276 2.93899 13.6678 3.72039 12.8864Z"
                        fill="#9CA3AF" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.0007 3.33268C8.61994 3.33268 7.50065 4.45197 7.50065 5.83268C7.50065 7.21339 8.61994 8.33268 10.0007 8.33268C11.3814 8.33268 12.5006 7.21339 12.5006 5.83268C12.5006 4.45197 11.3814 3.33268 10.0007 3.33268ZM5.83398 5.83268C5.83398 3.5315 7.69946 1.66602 10.0007 1.66602C12.3018 1.66602 14.1673 3.5315 14.1673 5.83268C14.1673 8.13387 12.3018 9.99935 10.0007 9.99935C7.69946 9.99935 5.83398 8.13387 5.83398 5.83268Z"
                        fill="#9CA3AF" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label for="phone" class="block mb-2 text-base font-medium text-gray-900 ">رقم الهاتف</label>
              <input type="tel" id="phone"
                class="w-full bg-transparent rounded-md border border-stroke py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2"
                placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                الإيميل
              </label>
              <div class="relative">
                <input type="email" placeholder="email@gamil.com"
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
                <span class="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M3.33398 4.16667C2.87756 4.16667 2.50065 4.54357 2.50065 5V15C2.50065 15.4564 2.87756 15.8333 3.33398 15.8333H16.6673C17.1238 15.8333 17.5007 15.4564 17.5007 15V5C17.5007 4.54357 17.1238 4.16667 16.6673 4.16667H3.33398ZM0.833984 5C0.833984 3.6231 1.95708 2.5 3.33398 2.5H16.6673C18.0442 2.5 19.1673 3.6231 19.1673 5V15C19.1673 16.3769 18.0442 17.5 16.6673 17.5H3.33398C1.95708 17.5 0.833984 16.3769 0.833984 15V5Z"
                        fill="#9CA3AF" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.984696 4.52154C1.24862 4.14449 1.76823 4.0528 2.14527 4.31673L10.0007 9.81554L17.8562 4.31673C18.2332 4.0528 18.7528 4.14449 19.0167 4.52154C19.2807 4.89858 19.189 5.41818 18.8119 5.68211L10.4786 11.5154C10.1917 11.7163 9.80977 11.7163 9.52284 11.5154L1.1895 5.68211C0.812463 5.41818 0.720767 4.89858 0.984696 4.52154Z"
                        fill="#9CA3AF" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                كلمة المرور
              </label>
              <div class="relative">
                <input type="password" placeholder="•••••••••"
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
                <span class="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.16797 10.0007C3.70773 10.0007 3.33464 10.3737 3.33464 10.834V16.6673C3.33464 17.1276 3.70773 17.5007 4.16797 17.5007H15.8346C16.2949 17.5007 16.668 17.1276 16.668 16.6673V10.834C16.668 10.3737 16.2949 10.0007 15.8346 10.0007H4.16797ZM1.66797 10.834C1.66797 9.45327 2.78726 8.33398 4.16797 8.33398H15.8346C17.2153 8.33398 18.3346 9.45327 18.3346 10.834V16.6673C18.3346 18.048 17.2153 19.1673 15.8346 19.1673H4.16797C2.78726 19.1673 1.66797 18.048 1.66797 16.6673V10.834Z"
                        fill="#9CA3AF" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10 2.50065C9.11594 2.50065 8.2681 2.85184 7.64298 3.47696C7.01786 4.10208 6.66667 4.94993 6.66667 5.83398V9.16732C6.66667 9.62756 6.29357 10.0007 5.83333 10.0007C5.3731 10.0007 5 9.62756 5 9.16732V5.83398C5 4.5079 5.52678 3.23613 6.46447 2.29845C7.40215 1.36077 8.67392 0.833984 10 0.833984C11.3261 0.833984 12.5979 1.36077 13.5355 2.29845C14.4732 3.23613 15 4.5079 15 5.83398V9.16732C15 9.62756 14.6269 10.0007 14.1667 10.0007C13.7064 10.0007 13.3333 9.62756 13.3333 9.16732V5.83398C13.3333 4.94993 12.9821 4.10208 12.357 3.47696C11.7319 2.85184 10.8841 2.50065 10 2.50065Z"
                        fill="#9CA3AF" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">تأكيد كلمة
                المرور
              </label>
              <div class="relative">
                <input type="password" placeholder="•••••••••"
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
                <span class="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.16797 10.0007C3.70773 10.0007 3.33464 10.3737 3.33464 10.834V16.6673C3.33464 17.1276 3.70773 17.5007 4.16797 17.5007H15.8346C16.2949 17.5007 16.668 17.1276 16.668 16.6673V10.834C16.668 10.3737 16.2949 10.0007 15.8346 10.0007H4.16797ZM1.66797 10.834C1.66797 9.45327 2.78726 8.33398 4.16797 8.33398H15.8346C17.2153 8.33398 18.3346 9.45327 18.3346 10.834V16.6673C18.3346 18.048 17.2153 19.1673 15.8346 19.1673H4.16797C2.78726 19.1673 1.66797 18.048 1.66797 16.6673V10.834Z"
                        fill="#9CA3AF" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10 2.50065C9.11594 2.50065 8.2681 2.85184 7.64298 3.47696C7.01786 4.10208 6.66667 4.94993 6.66667 5.83398V9.16732C6.66667 9.62756 6.29357 10.0007 5.83333 10.0007C5.3731 10.0007 5 9.62756 5 9.16732V5.83398C5 4.5079 5.52678 3.23613 6.46447 2.29845C7.40215 1.36077 8.67392 0.833984 10 0.833984C11.3261 0.833984 12.5979 1.36077 13.5355 2.29845C14.4732 3.23613 15 4.5079 15 5.83398V9.16732C15 9.62756 14.6269 10.0007 14.1667 10.0007C13.7064 10.0007 13.3333 9.62756 13.3333 9.16732V5.83398C13.3333 4.94993 12.9821 4.10208 12.357 3.47696C11.7319 2.85184 10.8841 2.50065 10 2.50065Z"
                        fill="#9CA3AF" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                العنوان
              </label>
              <div class="relative">
                <input type="text" placeholder="المنزل,الحي,البلدية,الولاية."
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
                <span class="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12 2C8.686 2 6 4.686 6 8C6 12.174 12 22 12 22C12 22 18 12.174 18 8C18 4.686 15.314 2 12 2ZM12 11C10.895 11 10 10.105 10 9C10 7.895 10.895 7 12 7C13.105 7 14 7.895 14 9C14 10.105 13.105 11 12 11Z"
                        fill="#9CA3AF" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                أظهر لنا حماسك في رسالة
              </label>
              <div class="relative">
                <textarea rows="5" placeholder="إكتب رسالة تحفيزية تظهر فيها حماسك للبدأ معنا."
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
              </div>
            </div>
          </div>
        </div>
        <!-- end step 2  -->
        <!-- stet 3 start -->
        <div v-if="currentStep === 3" class="-mx-4 flex flex-wrap">
          <div class="w-full px-4 md:w-1/2 ">
            <div class="mb-12">
              <label for="" class="mb-[10px] block text-base font-medium text-dark ">
                اسم المشروع
              </label>
              <input type="text" placeholder="اسم المشروع"
                class="w-full bg-transparent rounded-md border border-stroke py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2" />
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 ">
            <div class="mb-12">
              <label for="" class="mb-[10px] block text-base font-medium text-dark ">
                فكرة المشروع
              </label>
              <input type="text" placeholder="فكرة المشروع"
                class="w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2" />
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2">
            <div class="mb-12">
              <label for="job-select" class="mb-[10px] block text-base font-medium text-dark">
                الخدمة المطلوبة
              </label>
              <div class="relative">
                <svg xmlns="http://www.w3.org/2000/svg" @click="focusSelect"
                  class="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-2.5 cursor-pointer"
                  viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
                <select ref="selectRef"
                  class="w-full bg-transparent rounded-md border border-primary py-[10px] pl-10 pr-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 appearance-none">
                  <option>التوجيه والإرشاد</option>
                  <option>التدريب والتطوير</option>
                  <option>الوصول إلى التمويل</option>
                  <option>الدعم الإداري والقانوني</option>
                  <option>المساحات المكتبية الافتراضية</option>
                  <option>شبكات العلاقات</option>
                  <option>الدعم التقني</option>
                </select>
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 ">
            <div class="mb-12">
              <label for="" class="mb-[10px] block text-base font-medium text-dark ">
                عدد أصحاب المشروع
              </label>
              <input type="number" placeholder="01" value="01"
                class="w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2" />
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 ">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                وصف المشروع
              </label>
              <div class="relative">
                <textarea rows="5" placeholder="أكتب وصف لفكرة مشروعك تشرح فيه أهم النقاط الأساسية"
                  class="w-full bg-transparent rounded-md border border-stroke py-[10px] pr-3 pl-12 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2" />
              </div>
            </div>
          </div>
          <div class="w-full px-4 md:w-1/2 ">
            <div class="mb-12">
              <label class="mb-[10px] block text-base font-medium text-dark ">
                إرفع نموذج الأعمال التجاري BMC ( نموذج أولي )
              </label>
              <div class="relative">
                <label for="file"
                  class="flex min-h-[145px] w-full cursor-pointer items-center justify-center rounded-md border border-dashed border-primary p-6">
                  <div>
                    <input type="file" name="file" id="file" class="sr-only" />
                    <span
                      class="mx-auto mb-3 flex h-[50px] w-[50px] items-center justify-center rounded-full border border-stroke bg-white ">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2.5013 11.666C2.96154 11.666 3.33464 12.0391 3.33464 12.4993V15.8327C3.33464 16.0537 3.42243 16.2657 3.57871 16.4219C3.73499 16.5782 3.94695 16.666 4.16797 16.666H15.8346C16.0556 16.666 16.2676 16.5782 16.4239 16.4219C16.5802 16.2657 16.668 16.0537 16.668 15.8327V12.4993C16.668 12.0391 17.0411 11.666 17.5013 11.666C17.9615 11.666 18.3346 12.0391 18.3346 12.4993V15.8327C18.3346 16.4957 18.0712 17.1316 17.6024 17.6004C17.1336 18.0693 16.4977 18.3327 15.8346 18.3327H4.16797C3.50493 18.3327 2.86904 18.0693 2.4002 17.6004C1.93136 17.1316 1.66797 16.4957 1.66797 15.8327V12.4993C1.66797 12.0391 2.04106 11.666 2.5013 11.666Z"
                          fill="#3AAFA9"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.41074 1.91009C9.73618 1.58466 10.2638 1.58466 10.5893 1.91009L14.7559 6.07676C15.0814 6.4022 15.0814 6.92984 14.7559 7.25527C14.4305 7.58071 13.9028 7.58071 13.5774 7.25527L10 3.67786L6.42259 7.25527C6.09715 7.58071 5.56951 7.58071 5.24408 7.25527C4.91864 6.92984 4.91864 6.4022 5.24408 6.07676L9.41074 1.91009Z"
                          fill="#3AAFA9"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.0013 1.66602C10.4615 1.66602 10.8346 2.03911 10.8346 2.49935V12.4994C10.8346 12.9596 10.4615 13.3327 10.0013 13.3327C9.54106 13.3327 9.16797 12.9596 9.16797 12.4994V2.49935C9.16797 2.03911 9.54106 1.66602 10.0013 1.66602Z"
                          fill="#3AAFA9"></path>
                      </svg>
                    </span>
                    <span class="text-base text-body-color ">
                      سحب و إفلات أو
                      <span class="text-primary underline"> تصفح </span>
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- end step 3 -->
        <!-- end Step Content -->
        <!-- Button Group -->
        <div class="mt-5 flex justify-between items-center gap-x-2">
          <button v-if="currentStep > 1" @click="stepper('b')" type="button"
            class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            :disabled="currentStep === 1">
            <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="m9 18 6-6-6-6"></path>
            </svg>
            السابق
          </button>
          <div v-if="currentStep === 1" :disabled="currentStep === 1">
          </div>
          <button v-if="currentStep < stepsItems.length" @click="stepper('n')" type="button"
            class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 active:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none"
            :disabled="currentStep === stepsItems.length">
            التالي
            <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="m15 18-6-6 6-6"></path>
            </svg>
          </button>
          <button v-if="currentStep === stepsItems.length" @click="showErrorModal = true"
            class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 active:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none">
            إرسال
          </button>
        </div>
        <!-- End Button Group -->
      </div>
    </div>
  </div>
  <!-- end main -->
</div>
<!-- modal -->
<div v-if="showErrorModal"
  class="fixed inset-0 z-50 flex font-Cairo text-center items-center justify-center bg-black bg-opacity-50">
  <div
    class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">
    <svg class="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clip-rule="evenodd" />
    </svg>
    <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">تم التسجيل بنجلح</h2>
    <div class="text-gray-600 mb-8">
      تم تسجيل مشروعك بنجاح سيتم دراسته من قبل مختصينا وستتلقى إيميل يخبرك بالنتيجة.
    </div>
    <RouterLink to="/" @click="showErrorModal = false"
      class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border">
      العودة إلى الوصفحة الرئيسية
    </RouterLink>
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue';
const showErrorModal = ref(false);
const stepsItems = ["مرحبا", "معلومات المستخدم", "معلومات المشروع"];
const currentStep = ref(1); // No need for .value here in the template

function stepper(action) {
  if (action === 'n' && currentStep.value < stepsItems.length) {
    currentStep.value++;
    window.scrollTo({
      top: 0,      // Scroll to the top
      behavior: 'smooth'  // Smooth scrolling effect
    });
  } else if (action === 'b' && currentStep.value > 1) {
    currentStep.value--;
    window.scrollTo({
      top: 0,      // Scroll to the top
      behavior: 'smooth'  // Smooth scrolling effect
    });
  }
}

// Reference for the select element
const selectRef = ref(null)

// Focus the select when the icon is clicked
const focusSelect = () => {
  if (selectRef.value) {
    selectRef.value.focus() // Focuses the select dropdown
  }
}
</script>

<style scoped></style>
