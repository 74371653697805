<template><!-- about section -->
<section id="about-section" class="">
  <!-- Container -->
  <div class="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
    <!-- Component -->
    <div class="flex flex-col gap-8 lg:flex-row lg:gap-10" dir="rtl">
      <!-- Image -->
      <div class="w-full rounded-md items-center justify-center lg:w-2/5">
        <img src="../../assets/aboutBG.svg" alt="">
      </div>
      <!-- Content -->
      <div class="flex flex-col gap-8 lg:w-3/5" dir="rtl">
        <h2 class="mb-8 text-3xl font-Cairo md:text-5xl">من نحن؟</h2>
        <p class="text-sm font-Cairo sm:text-base">حاضنة الأعمال الرقمية "خطوة" هي منصة افتراضية متخصصة في دعم إنشاء
          وتطوير المشاريع الناشئة، موجهة نحو توفير جميع أنواع الدعم الإداري، التقني، والاقتصادي للمبادرين ورواد الأعمال.
          تعمل الحاضنة على مرافقة المشاريع منذ الفكرة الأولى وحتى تحقيق النجاح والتخرج، مع ضمان حصولها على التمويل
          اللازم للنمو المستدام. تقدم "خطوة" خدماتها بشكل رقمي بالكامل، مما يسمح بالوصول إلى الموارد والخبرات المتنوعة
          بسهولة ومرونة. يدير الحاضنة فريق أكاديمي ومهني يمتلك خبرة واسعة في ريادة الأعمال، مما يضمن تقديم استشارات
          متميزة وحلول فعّالة تسهم في تعزيز فرص النجاح والتوسع في السوق.</p>
      </div>
    </div>
    <!-- facts -->
    <div class="grid gap-5 sm:grid-cols-2 md:grid-cols-3 text-right md:gap-4 lg:gap-6">
      <!-- Item -->
      <div class="relative p-8 md:p-10">
        <h3 class="mb-4 text-3xl font-bold md:text-5xl">+300</h3>
        <p class="text-sm text-gray-500 font-Cairo ">استفاد أصحاب المشاريع الريادية المحتضنة لدى حاضنة "خطوة الافتراضية" من أكثر من
          300 ساعة تدريب مخصصة لتطوير مهاراتهم. تتنوع هذه التدريبات لتشمل مجالات إدارة الأعمال، التسويق، والتكنولوجيا،
          مما يساعد رواد الأعمال على تعزيز كفاءاتهم وتحسين أدائهم في السوق. تساهم هذه البرامج التدريبية في تمكين
          المشاركين من تحقيق أهدافهم وبناء مشاريع ناجحة، مما يعكس التزام الحاضنة بدعم الابتكار والنمو في البيئة
          الريادية.</p>
        <div class="absolute right-0 top-[29%] hidden h-2/5 border-r border-gray-300 md:block"></div>
      </div>
      <!-- Item -->
      <div class="relative p-8 md:p-10">
        <h3 class="mb-4 text-3xl font-bold md:text-5xl">30%</h3>
        <p class="text-sm text-gray-500 font-Cairo ">تتميز حاضنة "خطوة الافتراضية" بنجاح ملحوظ، حيث تسجل نسبة نجاح تتجاوز 30%
          للمؤسسات الناشئة المحتضنة لديها. يعكس هذا النجاح فعالية البرامج والدعم المقدم، مما يمكن رواد الأعمال من تطوير
          مشاريعهم بشكل فعّال. تساهم الحاضنة في توفير بيئة ملائمة للابتكار، بالإضافة إلى استشارات مهنية وتدريب متخصص،
          مما يعزز فرص النجاح والنمو في السوق التنافسي.</p>
        <div class="absolute right-0 top-[29%] hidden h-2/5 border-r border-gray-300 md:block"></div>
      </div>
      <!-- Item -->
      <div class="p-8 md:p-10">
        <h3 class="mb-4 text-3xl font-bold font-Cairo md:text-5xl">+100</h3>
        <p class="text-sm text-gray-500 font-Cairo ">تضم حاضنة "خطوة الافتراضية" أكثر من 100 مؤسسة ناشئة متنوعة في مجالات
          التكنولوجيا، الصحة، التعليم، والطاقة المتجددة. تقدم الحاضنة دعماً فنياً ومالياً واستشارات مهنية، مما يساعد
          المبتكرين على تحويل أفكارهم إلى مشاريع ناجحة. بفضل البرامج التدريبية وورش العمل، حققت العديد من هذه المؤسسات
          نمواً ملحوظاً وتوسعاً في قاعدة عملائها.</p>
      </div>
    </div>
  </div>
</section>
<!-- end about section -->
</template>

<script setup>

</script>

<style scoped></style>