<template>
<section id="experts-partners-section" dir="rtl">
  <!-- experts section -->
  <div class="experts-section font-Cairo">

    <!-- Container -->
    <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
      <!-- Title -->
      <!-- Container -->
      <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
        <!-- Component -->
        <div class="grid items-center gap-8 sm:gap-20 lg:grid-cols-2">
          <div>
            <h2 class="mb-4 max-w-2xl text-3xl font-Cairo md:text-5xl">من هم خبراؤنا؟</h2>
            <p class="mb-6 max-w-lg text-sm font-Cairo text-gray-500 sm:text-base lg:mb-12">تضم حاضنة "خطوة" فريقًا
              متميزًا يتكون من نخبة من الأساتذة الجامعيين المتخصصين في ريادة الأعمال والمقاولاتية، والذين يمتلكون خلفية
              أكاديمية قوية وخبرة واسعة في تطوير المشاريع وإدارة الأعمال. يسهم هؤلاء الأساتذة في تقديم الإرشاد الأكاديمي
              والتوجيه العملي، مما يساعد رواد الأعمال على تطوير أفكارهم بناءً على أفضل الممارسات والنظريات العلمية
              الحديثة.
              إلى جانب الفريق الأكاديمي، تحتوي "خطوة" على أعضاء مهنيين من أصحاب المشاريع الناجحة، الذين يجلبون خبراتهم
              العملية في مجالات متنوعة. يساهم هؤلاء المهنيون في تقديم المشورة العملية والمساعدة في توجيه رواد الأعمال
              نحو حلول مبتكرة وفعالة تتناسب مع التحديات الفعلية التي يواجهونها في السوق. يتميز هذا المزيج بين
              الأكاديميين وأصحاب المشاريع بتوفير دعم شامل يجمع بين النظرية والتطبيق، مما يضمن مرافقة مستدامة ومتينة
              للمشاريع الناشئة في مختلف مراحلها.</p>
          </div>
          <div>
            <img src="../../assets/expertsBG.svg" alt="" class="mx-auto inline-block h-full w-full max-w-2xl" />
          </div>
        </div>
      </div>
      <!-- Content -->
      <div
        class="grid grid-cols-1 justify-items-center gap-5 sm:grid-cols-2 sm:justify-items-stretch md:grid-cols-3 md:gap-4 lg:gap-6">
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person1.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover" />
          <p class="font-bold">أحمد الجربي</p>
          <p class="text-center text-sm text-gray-500"> عضو اللجنة العلمية </p>

        </div>
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person2.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover " />
          <p class="font-bold">أيمن زيد</p>
          <p class="text-center text-sm text-gray-500">عضو اللجنة العلمية </p>

        </div>
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person3.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover" />
          <p class="font-bold">يوسف بريك</p>
          <p class="text-center text-sm text-gray-500"> عضو اللجنة العلمية</p>

        </div>
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person8.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover" />
          <p class="font-bold">لحواو آمال</p>
          <p class="text-center text-sm text-gray-500">عضو اللجنة العلمية</p>

        </div>
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person4.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover" />
          <p class="font-bold">عمارة أمين</p>
          <p class="text-center text-sm text-gray-500">عضو اللجنة العلمية</p>

        </div>
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person5.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover" />
          <p class="font-bold">بوقرة عبد الصمد</p>
          <p class="text-center text-sm text-gray-500">مطور برمجيات</p>

        </div>
        <!-- Item -->
        <div class="mx-auto flex w-full flex-col items-center gap-4 py-8 text-center md:px-8 md:py-4 lg:px-12">
          <img src="../../assets/person6.jpg" alt="" class="mb-4 inline-block h-40 w-40 rounded-full object-cover" />
          <p class="font-bold">مقدود صلاح الدين</p>
          <p class="text-center text-sm text-gray-500">مطور برمجيات</p>

        </div>
      </div>
    </div>

  </div>
  <!-- end experts section -->
  <hr class="my-8 border-gray-300" />
  <!-- partners section -->
  <div class="relative isolate px-6 pt-14 lg:px-8">
    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
      <div
        class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
    </div>
    <div class="pb-16" style="font-family: 'Lato', sans-serif">
    <dh-component>
        <div class="container mx-auto pt-16">
            <div class="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto sm:mb-10 mb-16">
                <h2 tabindex="0" class="focus:outline-none text-center pt-4 mb-6 font-Cairo text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    الشركاء
                </h2>
                <p tabindex="0" class="focus:outline-none text-base md:text-lg lg:text-xl text-center text-gray-600 font-normal xl:w-10/12 xl:mx-auto">
                </p>
            </div>
            <div class="xl:py-16 lg:py-16 md:py-16 sm:py-16 px-6 flex flex-wrap">
              <div class="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r border-gray-200 xl:pb-4 pb-4 items-center">
                    <img tabindex="0" class="focus:outline-none w-1/2 rounded-full" src="../../assets/qoudyan.svg" alt="Qoudyan Logo" role="img" />
                </div>  
              <div class="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r border-gray-200 xl:pb-4 pb-4 items-center">
                    <img tabindex="0" class="focus:outline-none w-1/2" src="../../assets/elkhatib.svg" alt="El Khatib Logo" role="img" />
                </div>
                <div class="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r border-gray-200 xl:pb-4 pb-4 items-center">
                    <img tabindex="0" class="focus:outline-none w-1/2" src="../../assets/shift.svg" alt="Shift Logo" role="img" />
                </div>
                <div class="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center lg:border-b xl:border-b lg:border-l lg:border-r xl:border-l border-gray-200 xl:pb-4 pb-4 items-center">
                    <img tabindex="0" class="focus:outline-none w-1/2 rounded-full" src="../../assets/chargily.svg" alt="Chargily Logo" role="img" />
                </div>
               </div>
        </div>
    </dh-component>
</div>




    <div
      class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      aria-hidden="true">
      <div
        class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
    </div>
  </div>
  <!-- end partners section -->
</section>
</template>

<script setup>
</script>

<style scoped></style>