import { createRouter, createWebHistory } from 'vue-router'
import Home from '../layouts/HomeLayout.vue';
import Login from '../layouts/LoginLayout.vue';
import Register from '../layouts/RegisterLayout.vue';
import AdminLayout from '../layouts/AdminLayout.vue';
import AdminDashboard from '@/pages/Admin/AdminDashboard.vue';
import UserLayout from '../layouts/UserLayout.vue';
import ExpertLayout from '@/layouts/ExpertLayout.vue';
import ExpertDashboard from '@/pages/Expert/ExpertDashboard.vue';
import ExpertPending from '@/pages/Expert/ExpertPending.vue';
import ExpertBMC from '@/pages/Expert/ExpertBMC.vue';
import ExpertFinancial from '@/pages/Expert/ExpertFinancial.vue';
import ExpertProfile from '@/pages/Expert/ExpertProfile.vue';
import ExpertProject from '@/pages/Expert/ExpertProject.vue';
import FreeReservations from '@/pages/Expert/FreeReservations.vue';
import UserDashboard from '@/pages/User/UserDashboard.vue';
import UserProjectInfo from '@/pages/User/UserProjectInfo.vue';
import UserReserveBuildBmc from '@/pages/User/UserReserveBuildBmc.vue';
import UserReserveCorrectBmc from '@/pages/User/UserReserveCorrectBmc.vue';
import UserReserveFinancialStudy from '@/pages/User/UserReserveFinancialStudy.vue';
import UserProfile from '@/pages/User/UserProfile.vue';
import UserLabel from '@/pages/User/UserLabel.vue';
import UserFinance from '@/pages/User/UserFinance.vue';
import UserReserveExpert from '@/pages/User/UserReserveExpert.vue';
import UserVisualIdentity from '@/pages/User/UserVisualIdentity.vue';
import UserLonda from '@/pages/User/UserLonda.vue';
import AdminUserManagment from '@/pages/Admin/AdminUserManagment.vue';
import AdminExpertManagment from '@/pages/Admin/AdminExpertManagment.vue';
import AdminStatistics from '@/pages/Admin/AdminStatistics.vue';
import AdminProfile from '@/pages/Admin/AdminProfile.vue';
const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path:'',
        name : 'AdminDashboard',
        component: AdminDashboard
      },
      {
        path:'profile',
        name : 'AdminProfile',
        component: AdminProfile
      },
      {
        path:'user-managment',
        name : 'AdminUserManagment',
        component: AdminUserManagment
      },
      {
        path:'expert-managment',
        name : 'AdminExpertManagment',
        component: AdminExpertManagment
      },
      {
        path:'admin-statistics',
        name : 'AdminStatistics',
        component: AdminStatistics
      },
    ]
  },
  // ------- Start user ----------
  {
    path: '/user',
    component: UserLayout,
    children: [
      {
        path: '',
        name: 'UserDashboard',
        component: UserDashboard,
      },
      {
        path: 'user-project-info',
        name: 'UserProjectInfo',
        component: UserProjectInfo,
      },
      {
        path: 'user-reserve-build-bmc',
        name: 'UserReserveBuildBmc',
        component: UserReserveBuildBmc,
      },
      {
        path: 'user-reserve-correct-bmc',
        name: 'UserReserveCorrectBmc',
        component: UserReserveCorrectBmc,
      },
      {
        path: 'user-reserve-financial-study',
        name: 'UserReserveFinancialStudy',
        component: UserReserveFinancialStudy,
      },
      {
        path: 'user-profile',
        name: 'UserProfile',
        component: UserProfile,
      },
      {
        path: 'user-label',
        name: 'UserLabel',
        component: UserLabel,
      },
      {
        path : 'user-londa',
        name : 'UserLonda',
        component : UserLonda
      },
      {
        path : 'user-finance',
        name : 'UserFinance',
        component : UserFinance
      },
      {
        path : 'user-londa',
        name : 'UserLonda',
        component : UserLonda
      },
      {
        path : 'user-free-expert',
        name : 'UserReserveExpert',
        component : UserReserveExpert
      },
      {
        path : 'user-visual-identity',
        name : 'UserVisualIdentity',
        component : UserVisualIdentity
      }
    ],
  },
  // ------- Start expert ----------
  {
    path: '/expert',
    component: ExpertLayout,
    children: [
      {
        path: '',
        name: 'ExpertDashboard',
        component: ExpertDashboard,
      },
      {
        path: 'pending-project',
        name: 'ExpertPending',
        component: ExpertPending,
      },
      {
        path: 'project1',
        name: 'ExpertProject',
        component: ExpertProject,
      },
      {
        path: 'bmc-reservations',
        name: 'ExpertBMC',
        component: ExpertBMC,
      },
      {
        path: 'financial-study-reservations',
        name: 'ExpertFinancial',
        component: ExpertFinancial,
      },
      {
        path: 'profile',
        name: 'ExpertProfile',
        component: ExpertProfile,
      },
      {
        path: 'free-reservations',
        name: 'FreeReservations',
        component: FreeReservations,
      },
    ],
  },
  // {
  //   path: '/expert/pending-project',
  //   name: 'PendingProject',
  //   component: PendingProject,
  // },
  // {
  //   path: '/expert/bmc-reservations',
  //   name: 'BMCReservations',
  //   component: BMCReservations,
  // },
  // {
  //   path: '/expert/financial-study-reservations',
  //   name: 'FinancialStudyReservations',
  //   component: FinancialStudyReservations,
  // },
  // ------- End expert ----------
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router