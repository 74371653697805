<template>
  <div class="bg-gray-100 font-sans h-screen">

<div class="container mx-auto p-6">
  <!-- Welcome Section -->
  <div class="bg-white shadow-lg rounded-lg p-12 mb-8 mt-10 text-center">
    <h1 class="text-5xl font-bold text-gray-800 font-Cairo">مرحبا بك في لوحة تحكم  المستخدم</h1>
    <h1 class="mt-6 text-xl  text-gray-800 font-Cairo">
      مرحباً بكم في حاضنة "خطوة الافتراضية"!
    </h1>
    <p class="mt-6 text-xl text-gray-600 font-Cairo">
      نحن سعداء بانضمامكم إلينا في هذه الرحلة المليئة بالابتكار والفرص، هدفنا هو دعمك ومساعدتك في كل خطوة على الطريق!
    </p>
    <a href="/user/user-reserve-build-bmc">
      <button  class="font-Cairo mt-8 bg-[#3aafa9] text-white px-8 py-4 text-lg rounded-lg hover:bg-teal-600 focus:outline-none focus:ring focus:ring-teal-300">
        إبدأ الآن
      </button>
    </a>
  </div>
</div>

</div>
</template>

<script>
export default {
  name: 'UserDashboard',
};
</script>


