<template><!-- services section -->
<div id="Services-Pricing-section">
    <section class=" text-black relative isolate px-6 pt-14 lg:px-8" dir="rtl">
        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true">
            <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
        </div>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div class="mx-auto max-w-lg text-center">
                <h2 class="text-3xl font-Cairo sm:text-4xl">خدماتنا</h2>

                <p class="mt-4 font-Cairo text-gray-600">
                    تقدم حاضنة الأعمال الرقمية "خطوة" مجموعة شاملة من الخدمات لدعم وتطوير المشاريع الناشئة من الفكرة إلى
                    مرحلة النمو والتوسع. تشمل هذه الخدمات ما يلي:
                </p>
            </div>

            <div class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <polyline points="8 16 10 10 16 8 14 14 8 16" />
                        <circle cx="12" cy="12" r="9" />
                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">التوجيه والإرشاد</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">
                        توفر "خطوة" جلسات إرشادية مستمرة مع خبراء مختصين في مجالات متنوعة مثل التسويق، التمويل، وتطوير
                        المنتجات، بهدف مساعدة رواد الأعمال على اتخاذ قرارات مستنيرة وتجنب الأخطاء الشائعة.
                    </p>
                </a>

                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">التدريب والتطوير</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">تتيح الحاضنة برامج تدريبية مكثفة تغطي جوانب متعددة
                        من
                        إدارة الأعمال، التكنولوجيا، الابتكار، والتمويل، لتمكين المؤسسين من اكتساب المهارات اللازمة
                        لإدارة
                        شركاتهم بفعالية.</p>
                </a>

                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />

                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">الوصول إلى التمويل</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">
                        تساعد "خطوة" المشاريع الناشئة في الوصول إلى المستثمرين وصناديق التمويل، من خلال إرشاد مباشر أو
                        تنظيم
                        فعاليات لعرض المشاريع أمام المستثمرين المحتملين.
                    </p>
                </a>

                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">الدعم الإداري والقانوني</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">
                        تقدم الحاضنة خدمات دعم قانونية وإدارية، تشمل تسجيل الشركات، إعداد العقود، وإدارة الموارد
                        البشرية،
                        مما يخفف العبء على رواد الأعمال ويتيح لهم التركيز على نمو أعمالهم.
                    </p>
                </a>

                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">المساحات المكتبية الافتراضية</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">
                        تتيح "خطوة" للشركات الناشئة إمكانية الاستفادة من مساحات عمل افتراضية توفر بيئة رقمية متكاملة،
                        تلبي
                        احتياجات الشركات دون الحاجة إلى مساحات فعلية.
                    </p>
                </a>

                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="9" cy="7" r="4" />
                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        <path d="M16 11h6m-3 -3v6" />
                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">شبكات العلاقات</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">
                        تعمل "خطوة" على بناء شبكات علاقات قوية تربط رواد الأعمال بالمستثمرين والشركاء والموردين، مما
                        يفتح
                        لهم فرصًا جديدة للتعاون والنمو.
                    </p>
                </a>
                <a class="block rounded-xl border border-gray-300 p-8 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50"
                    href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="size-10 text-[#3AAFA9]" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                    </svg>

                    <h2 class="mt-4 text-xl font-Cairo text-black">الدعم التقني</h2>

                    <p class="mt-1 font-Cairo text-sm text-gray-600">
                        تقدم الحاضنة مساعدة تقنية تشمل تطوير البرمجيات، تحسين الأداء الرقمي، وتطبيق حلول التكنولوجيا
                        المتقدمة لتطوير المنتجات والخدمات الرقمية.
                    </p>
                </a>

            </div>
        </div>
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true">
            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3AAFA9] to-[#2B7A78] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          " />
        </div>
    </section>
    <!-- end services section -->
    <!-- start pricing section -->
    <section class="py-20" dir="rtl">
        <div class="container px-4 mx-auto">
            <div class="max-w-2xl mx-auto mb-16 text-center">
                <h2 class="text-4xl font-Cairo lg:text-5xl"> اختر خطة الدعم المناسبة لك</h2>
            </div>
            <div class="flex flex-wrap font-Cairo items-stretch -mx-4">
                <div class="flex mt-4 w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0">
                    <div
                        class="flex flex-grow flex-col p-6 space-y-6 rounded shadow sm:p-8  bg-gray-50">
                        <div class="space-y-2">
                            <h4 class="text-2xl font-bold">دراسة نموذج العمل التجاري للمشاريع</h4>
                            <span class="text-6xl font-bold">5000 دج</span>
                        </div>
                        <ul class="flex-1 mb-6  text-gray-600">
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تحليل السوق</span>
                            </li>
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تطوير القيمة المقترحة</span>
                            </li>
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تحديد قنوات التوزيع</span>
                            </li>
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تقييم مصادر الإيرادات</span>
                            </li>
                        </ul>
                        <button type="button"
                            class="inline-block px-5 py-3 font-semibold tracking-wider text-center rounded  bg-[#2B7A78]  text-gray-50">استفد من الخدمة</button>
                    </div>
                </div>
                <div class="flex mt-4  w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0">
                    <div
                        class="flex flex-grow flex-col p-6 space-y-6 rounded shadow sm:p-8  bg-gray-50">
                        <div class="space-y-2">
                            <h4 class="text-2xl font-bold">بناء الهوية البصرية وتسويق المشاريع</h4>
                            <span class="text-6xl font-bold">5000 دج
                            </span>
                        </div>
                        <ul class="space-y-2  text-gray-600">
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تصميم الشعار</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>إنشاء دليل الهوية البصرية</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>استراتيجية التسويق الرقمي</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تطوير محتوى تسويقي</span>
                            </li>
                        </ul>
                        <a rel="noopener noreferrer" href="#"
                            class="inline-block w-full px-5 py-3 font-semibold tracking-wider text-center rounded  bg-[#2B7A78]  text-gray-50">استفد من الخدمة</a>
                    </div>
                </div>
                <div class="flex mt-4  w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0">
                    <div
                        class="flex flex-grow flex-col p-6 space-y-6 rounded shadow sm:p-8  bg-gray-50">
                        <div class="space-y-2">
                            <h4 class="text-2xl font-bold">المساعدة من أجل حماية براءة الاختراع والملكية الفكرية</h4>
                            <span class="text-6xl font-bold">5000 دج
                            </span>
                        </div>
                        <ul class="space-y-2  text-gray-600">
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>استشارات قانونية</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>إجراءات التسجيل</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>التوجيه نحو الحصول على الوسوم</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>توعية حول حقوق الملكية الفكرية</span>
                            </li>
                        </ul>
                        <a rel="noopener noreferrer" href="#"
                            class="inline-block w-full px-5 py-3 font-semibold tracking-wider text-center rounded  bg-[#2B7A78]  text-gray-50">استفد من الخدمة</a>
                    </div>
                </div>
                <div class="flex mt-4  w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0">
                    <div
                        class="flex flex-grow flex-col p-6 space-y-6 rounded shadow sm:p-8  bg-gray-50">
                        <div class="space-y-2">
                            <h4 class="text-2xl font-bold">التدريب والدعم من أجل الحصول على التمويل للمشروع</h4>
                            <span class="text-6xl font-bold">5000 دج
                            </span>
                        </div>
                        <ul class="space-y-2  text-gray-600">
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تحليل احتياجات التمويل</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تحضير مستندات التمويل</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>التوجيه في البحث عن الجهات الممولة</span>
                            </li>
                            <li class="flex items-start space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تدريب على المهارات التفاوضية</span>
                            </li>
                        </ul>
                        <a rel="noopener noreferrer" href="#"
                            class="inline-block w-full px-5 py-3 font-semibold tracking-wider text-center rounded  bg-[#2B7A78]  text-gray-50">استفد من الخدمة</a>
                    </div>
                </div>
                <div class="flex mt-4 w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0">
                    <div
                        class="flex flex-grow flex-col p-6 space-y-6 rounded shadow sm:p-8  bg-gray-50">
                        <div class="space-y-2">
                            <h4 class="text-2xl font-bold">خدمات بناء المنصات والمواقع الإلكترونية والتطبيقات</h4>
                            <span class="text-6xl font-bold">5000 دج</span>
                        </div>
                        <ul class="flex-1 mb-6  text-gray-600">
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تطوير المواقع الإلكترونية</span>
                            </li>
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>بناء التطبيقات المحمولة</span>
                            </li>
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>تحسين تجربة المستخدم (UX/UI)</span>
                            </li>
                            <li class="flex mb-2 space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6  text-[#2B7A78]">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>توفير خدمات الاستضافة والصيانة</span>
                            </li>
                        </ul>
                        <button type="button"
                            class="inline-block px-5 py-3 font-semibold tracking-wider text-center rounded  bg-[#2B7A78]  text-gray-50">استفد من الخدمة</button>
                    </div>
                </div>
                <div class="flex mt-4  w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0">
                    <div
                        class="flex flex-grow flex-col p-6 space-y-6 rounded shadow sm:p-8  bg-[#2B7A78]  text-gray-50">
                        <div class="space-y-2">
                            <h4 class="text-2xl font-bold">باقة الدعم الشامل</h4>
                            <span class="text-6xl font-bold">20000 دج
                            </span>
                        </div>
                        <ul class="flex-1 space-y-2">
                            <li class="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>دراسة نموذج العمل التجاري للمشاريع</span>
                            </li>
                            <li class="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>بناء الهوية البصرية وتسويق المشاريع</span>
                            </li>
                            <li class="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>المساعدة من أجل حماية براءة الاختراع والملكية الفكرية</span>
                            </li>
                            <li class="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>التدريب والدعم من أجل الحصول على التمويل للمشروع</span>
                            </li>
                            <li class="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="flex-shrink-0 w-6 h-6">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>خدمات بناء المنصات والمواقع الإلكترونية والتطبيقات</span>
                            </li>
                        </ul>
                        <a rel="noopener noreferrer" href="#"
                            class="inline-block w-full px-5 py-3 font-bold tracking-wider text-center rounded  bg-gray-100  text-[#2B7A78]">استفد من الخدمة</a>
                    </div>
                </div>

            </div>

        </div>
    </section>
    <!-- end pricing section -->
</div>
</template>
<script setup>

</script>

<style scoped></style>