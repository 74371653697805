<template><!-- contact section -->
<section id="contact-section" class="py-14 font-Cairo" dir="rtl">
  <div class="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
    <div class="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
      <div class="max-w-lg space-y-3">
        <p class="text-gray-800 text-3xl font-Cairo sm:text-4xl">
          تواصل معنا
        </p>
        <img src="../../assets/contact.svg" alt="" class="p-6 h-52 md:h-64">
        <p>
          لا تتردد في التواصل معنا، فريقنا متواجد دوما للاجابة عن إستفساراتك ومساعدتك على حل مشاكلك ومرافقتك للوصول لأهدافك .
        </p>
        <div class="space-y-4">
          <p class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              class="w-5 h-5 mr-2 sm:mr-6">
              <path fill-rule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd"></path>
            </svg>
            <span>Msila</span>
          </p>
          <p class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              class="w-5 h-5 mr-2 sm:mr-6">
              <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z">
              </path>
            </svg>
            <span>00213.661.47.11.70</span>
          </p>
          <p class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              class="w-5 h-5 mr-2 sm:mr-6">
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
            </svg>
            <span>khotwaincub</span>
          </p>
          <p class="flex items-center">
            <svg class="w-5 h-5 mr-2 sm:mr-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
              stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
            </svg>
            <span>@khotwaincub</span>
          </p>
          <p class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              class="w-5 h-5 mr-2 sm:mr-6">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg>
            <span>khotwaincub@gmail.com</span>
          </p>

        </div>
      </div>
      <div
        class="flex-1 mt-12 sm:max-w-lg lg:max-w-md mx-auto px-4 p-8 sm:px-8 sm:rounded-xl block rounded-xl border border-gray-300 shadow-xl transition hover:border-[#3AAFA9]/50 hover:shadow-[#3AAFA9]/50">
        <form onsubmit="event.preventDefault()" class="space-y-5">
          <div>
            <label class="font-Cairo">
              الإسم الكامل
            </label>
            <input type="text" required
              class="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border border-gray-300  focus:border-[#3AAFA9]/50 shadow-sm rounded-lg" />
          </div>
          <div>
            <label class="font-Cairo">
              الإميل
            </label>
            <input type="email" required
              class="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border border-gray-300   focus:border-[#3AAFA9]/50 shadow-sm rounded-lg" />
          </div>
          <div>
            <label class="font-Cairo">
              رقم الهاتف
            </label>
            <div class="relative mt-2 " dir="ltr">
              <div class="absolute inset-y-0 left-3 my-auto h-6 flex items-center border-r pr-2">
                <select class="text-sm bg-transparent outline-none rounded-lg h-full">
                  <option>US</option>
                  <option>ES</option>
                  <option>MR</option>
                </select>
              </div>
              <input type="number" placeholder="+1 (555) 000-000" required
                class="w-full pl-[4.5rem] pr-3 py-2 appearance-none bg-transparent outline-none border border-gray-300  focus:border-[#3AAFA9]/50 shadow-sm rounded-lg" />
            </div>
          </div>
          <div>
            <label class="font-Cairo">
              الرسالة
            </label>
            <textarea required
              class="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border border-gray-300  focus:border-[#3AAFA9]/50 shadow-sm rounded-lg"></textarea>
          </div>
          <button
            class="w-full px-4 py-2 text-white font-Cairo bg-gray-800 hover:bg-gray-700 active:bg-gray-900 rounded-lg duration-150">
            إرسال
          </button>
        </form>
      </div>
    </div>
  </div>

</section>

<!-- end contact section -->
</template>

<script setup>

</script>

<style scoped></style>