<template>
<div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4 font-Cairo ">
    <div class="min-w-0 rounded-lg shadow-xs overflow-hidden  bg-gray-800">
        <div class="p-4 flex items-center">
            <div class="p-3 rounded-full text-orange-100 bg-orange-500 mr-4">
                <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
                    <path
                        d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z">
                    </path>
                </svg>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-gray-400">
                    عدد المستخدمين
                </p>
                <p class="text-lg font-semibold text-gray-200">
                    6389
                </p>
            </div>
        </div>
    </div>
    <div class="min-w-0 rounded-lg shadow-xs overflow-hidden bg-gray-800">
        <div class="p-4 flex items-center">
            <div class="p-3 rounded-full text-green-100 bg-green-500 mr-4">
                <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
                    <path fill-rule="evenodd"
                        d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"></path>
                </svg>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-gray-400">
                    مجموع الإشتراكات
                </p>
                <p class="text-lg font-semibold text-gray-200">
                     46,760.89 دج
                </p>
            </div>
        </div>
    </div>
    <div class="min-w-0 rounded-lg shadow-xs overflow-hidden bg-gray-800">
        <div class="p-4 flex items-center">
            <div class="p-3 rounded-full text-blue-100 bg-blue-500 mr-4">
                <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
                    <path
                        d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z">
                    </path>
                </svg>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-gray-400">
                    عدد المشاركين في الخدمات
                </p>
                <p class="text-lg font-semibold text-gray-200">
                    376
                </p>
            </div>
        </div>
    </div>
    <div class="min-w-0 rounded-lg shadow-xs overflow-hidden bg-gray-800">
        <div class="p-4 flex items-center">
            <div class="p-3 rounded-full text-teal-100 bg-teal-500 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path
                        d="M3.75 3A1.75 1.75 0 0 0 2 4.75v3.26a3.235 3.235 0 0 1 1.75-.51h12.5c.644 0 1.245.188 1.75.51V6.75A1.75 1.75 0 0 0 16.25 5h-4.836a.25.25 0 0 1-.177-.073L9.823 3.513A1.75 1.75 0 0 0 8.586 3H3.75ZM3.75 9A1.75 1.75 0 0 0 2 10.75v4.5c0 .966.784 1.75 1.75 1.75h12.5A1.75 1.75 0 0 0 18 15.25v-4.5A1.75 1.75 0 0 0 16.25 9H3.75Z" />
                </svg>


            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-gray-400">
                    عدد المشاريع المحتضنة
                </p>
                <p class="text-lg font-semibold text-gray-200">35</p>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>