<template>
<header class="absolute inset-x-0 top-0 z-50" dir="rtl">
      <nav class="flex items-center justify-between p-6 lg:px-8 f" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Your Company</span>
            <img class="h-8 w-auto" src="@/assets/11-01.svg" alt="" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <a
            v-for="item in navigation"
            :key="item.name"
            :href="item.href"
            class="text-sm font-Cairo leading-6 text-gray-900"
            >{{ item.name }}</a
          >
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <RouterLink to="/login" class="text-sm font-Cairo leading-6 text-gray-900"
            >الحساب <span aria-hidden="true">&rarr;</span></RouterLink
          >
        </div>
      </nav>
      <Dialog
        class="lg:hidden"
        @close="mobileMenuOpen = false"
        :open="mobileMenuOpen"
        dir="rtl"
      >
        <div class="fixed inset-0 z-50" />
        <DialogPanel
          class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Your Company</span>
              <img class="h-8 w-auto" src="@/assets/11-01.svg" alt="" />
            </a>
            <button
              type="button"
              class="-m-2.5 rounded-md p-2.5 text-gray-700"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a @click="mobileMenuOpen = false"
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-Cairo leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ item.name }}</a
                >
              </div>
              <div class="py-6">
                <RouterLink
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-Cairo leading-7 text-gray-900 hover:bg-gray-50"
                  to="/login"
                  >الحساب</RouterLink
                >
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { RouterLink } from 'vue-router';

const navigation = [
  { name: 'الرئيسية', href: '#' },
  { name: 'من نحن', href: '#about-section' },
  { name: 'الخدمات و الأسعار', href: '#Services-Pricing-section' },
  { name: 'الشركاء و الخبراء', href: '#experts-partners-section' },
  { name: 'المشاريع المحتضنة', href: '#projects-section' },
  { name: 'اهتماماتنا', href: '#OurInterests-section' },
  { name: 'تواصل معنا', href: '#contact-section' },
]

const mobileMenuOpen = ref(false)
</script>

<style scoped></style>