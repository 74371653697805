<template>
<div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
  <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
    <h2
      class="max-w-lg mb-6 font-Cairo text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
      إختر طريقة التمويل المناسبة لمشروعك
    </h2>
    <p class="text-base text-gray-700 md:text-lg font-Cairo">
      نحن هنا لدعم مشروعك في الحصول على التمويل المناسب. يمكنك الاختيار بين التمويل الحكومي الذي يوفره عدد من الجهات
      الحكومية، أو التمويل الخاص من مستثمرين وشركات استثمارية. كل خيار له مزاياه وشروطه، ونحن مستعدون لمساعدتك في اتخاذ
      القرار الأنسب لاحتياجات مشروعك.
      <br>
      لا تتردد في التواصل معنا لمزيد من التفاصيل والاستشارات!
      <br>
      مع تحياتنا،
      <br>
      فريق حاضنة "خطوة "
    </p>
  </div>

  <div class="max-w-6xl mx-auto font-[sans-serif]">
    <div class="grid md:grid-cols-2 gap-4 mt-4">

      <div
        class="bg-[#17252A] text-center text-white max-sm:px-8 px-12 py-8 w-full rounded flex flex-col justify-between h-full">
        <div>
          <h2 class="text-2xl font-Cairo font-bold">
            الصندوق الحزائري لتمويل المؤسسات الناشئة ASF
          </h2>
          <div class="mt-4">
            <ul class="space-y-1 list-inside items-center justify-center text-center text-gray-400">
              <li class="flex items-center justify-center py-1 text-center font-Cairo">
                <svg class="w-3.5 h-3.5 me-2 text-[#3aafa9] flex-shrink-0" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                الخطوة الأولى
              </li>
              <li class="flex items-center justify-center py-1 text-center font-Cairo">
                <svg class="w-3.5 h-3.5 me-2 text-[#3aafa9] flex-shrink-0" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                الخطوةالثانية
              </li>
              <li class="flex items-center justify-center text-center py-1 font-Cairo">
                <svg class="w-3.5 h-3.5 me-2 text-gray-400 flex-shrink-0" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                الخطوةالثالثة
              </li>
            </ul>
          </div>
        </div>

        <button type="button" @click="showSuccessModel = true"
          class="mt-6 w-full font-Cairo px-6 py-3 rounded text-sm tracking-wider border-none outline-none bg-gray-600 hover:bg-gray-700">إبدأ</button>
      </div>

      <div
        class="bg-[#17252A] text-center text-white max-sm:px-8 px-12 py-8 w-full rounded flex flex-col justify-between h-full">
        <div>
          <h2 class="text-2xl font-Cairo font-bold">المستثمرون الخواص</h2>
          <div class="mt-4">
            <ul class="space-y-1 list-inside items-center justify-center text-center text-gray-400">
              <li class="flex items-center justify-center py-1 text-center font-Cairo">
                <svg class="w-3.5 h-3.5 me-2 text-[#3aafa9] flex-shrink-0" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                المستثمر الأول
              </li>
              <li class="flex items-center justify-center py-1 text-center font-Cairo">
                <svg class="w-3.5 h-3.5 me-2 text-[#3aafa9] flex-shrink-0" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                المستثمر الثاني
              </li>
              <li class="flex items-center justify-center text-center py-1 font-Cairo">
                <svg class="w-3.5 h-3.5 me-2 text-gray-400 flex-shrink-0" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                المستثمر الثالث
              </li>
            </ul>
          </div>
        </div>

        <button type="button" @click="showSuccessModel = true"
          class="mt-6 font-Cairo w-full px-6 py-3 rounded text-sm tracking-wider border-none outline-none bg-gray-600 hover:bg-gray-700">إبدأ</button>
      </div>

    </div>
  </div>



</div>
<!-- showTempModel  -->
<div v-if="showSuccessModel" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
  <div
    class="justify-between relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-white dark:bg-gray-50 dark:text-gray-800">
    <button class="absolute top-2 right-2" @click="showSuccessModel = false">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="flex-shrink-0 w-6 h-6">
        <polygon
          points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313">
        </polygon>
      </svg>
    </button>
    <svg class="mb-4 h-20 w-20 text-[#3aafa9] mx-auto" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clip-rule="evenodd" />
    </svg>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
        </svg> 
        <svg xmlns="http://www.w3.org/2000/svg" class="mb-4 h-20 w-20 text-[#ffc234] mx-auto" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
        </svg>-->

    <h2 class="text-2xl mb-4 text-gray-800 text-center font-Cairo">تم الإختيار بنجاح</h2>
    <div class="text-gray-600 mb-8 font-Cairo">
      شكرا لك ، سيتم الرد عليك في اقرب وقت ممكن
    </div>
    <button @click="showSuccessModel = false"
      class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100  border font-Cairo">
      ارجع الى الصفحة الرئيسية
    </button>
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
const showSuccessModel = ref(false)
</script>